import { Alert, Select } from "antd";
import { useMemo, useState } from "react";
import { useLayoutContext } from "../../providers/layout.provider";
import CreateCustomer from "./create-customer.page";
import CreateOrg from "./create-org.page";

export default function FirstTimePage() {
  const [type, setType] = useState<string | undefined>(undefined);
  const { isMobile } = useLayoutContext();

  const options = useMemo(() => {
    const _options = [
      { value: "admin", label: "I'm a business owner" },
      {
        value: "employee",
        label: "I'm a employee and would like to join a organization",
      },
    ];
    return _options;
  }, []);

  return (
    <div className="divCenterAlign divColumn w-100">
      <div
        className="greeting divCenterAlign divColumn mb-0"
        style={{ height: 300 }}
      >
        <h1>Hello there!</h1>
        <p>What would you like to do?</p>
        <div className="divColumn">
          <Select
            id="first-time-select"
            defaultValue=""
            onChange={(value) => setType(value)}
            style={{ width: isMobile ? 100 : 420 }}
            options={options}
          />
        </div>
      </div>

      {type === "employee" && (
        <Alert
          message="Please ask your organization admin to add you."
          type="warning"
        />
      )}

      {type === "admin" && <CreateOrg />}

      {type === "customer" && <CreateCustomer />}
    </div>
  );
}
