import { DeleteOutlined } from "@ant-design/icons";
import { Avatar, Flex, Input, List, Modal, Select } from "antd";
import { AxiosError } from "axios";
import Alert from "antd/es/alert/Alert";
import { useFormik } from "formik";
import moment from "moment";
import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useDebounce } from "use-debounce";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";
import { useCancelSale, useCreateOrgSale } from "../../../../api/api";
import {
  useGetCustomerStoreCredits,
  useGetOrgSetting,
  useSearchRewardLevelByExternalIdLast4,
} from "../../../../api/api.get";
import ErrorPanel from "../../../../components/error.panel.component";
import { PaymentPendingLottieLoading } from "../../../../components/lottie.viewer.component";
import { useMessage } from "../../../../components/notifications/message";
import { useCurrency } from "../../../../hooks/useCurrency";
import { usePaymentOptions } from "../../../../hooks/usePaymentOptions";
import { useSalesTerminalsHook } from "../../../../hooks/useSalesTerminalsHook";
import {
  ICreateSaleResponse,
  IProduct,
  PAYMENT_TYPE,
} from "../../../../types/types";
import { toFixed } from "../../../../utils/number.utils";
import {
  applyCoupons,
  PAYMENT_TYPES_NEEDING_PHONE_NUMBERS,
} from "../../../../utils/orders.utils";
import { calculateProductTotal } from "../../../../utils/products.utils";
import Checkbox from "antd/es/checkbox/Checkbox";

export default function ConfirmCheckout({
  open,
  onCancel,
  onOK,
  itemTotal,
  items,
  onRemoveItems,
}: {
  itemTotal: number;
  items: IProduct[];
  open: boolean;
  onOK: () => void;
  onCancel: () => void;
  onRemoveItems: (item: IProduct) => void;
}) {
  const { id } = useParams();
  const { areaCode, toAmountDisplay } = useCurrency();
  const { data: orgData } = useGetOrgSetting(id);
  const { mutateAsync, isPending } = useCreateOrgSale(id);
  const { terminalId } = useSalesTerminalsHook();

  const [searchCouponText, setCouponSearchText] = useState<string>("");
  const [debouncedSearchCouponText] = useDebounce(searchCouponText, 500);
  const { messageError, notification } = useMessage();
  const { paymentOptions } = usePaymentOptions();
  const [applyStoreCredits, setApplyStoreCredits] = useState<boolean>(false);

  const { mutateAsync: cancelSaleAsync, isPending: isCancelPending } =
    useCancelSale(id, terminalId);

  const {
    data: couponData,
    error,
    refetch,
  } = useSearchRewardLevelByExternalIdLast4(
    id,
    debouncedSearchCouponText,
    false,
  );

  const { points: pointsTotal, coupon: appliedCoupon } = applyCoupons(
    itemTotal,
    couponData ? [couponData] : [],
  );

  const formik = useFormik({
    initialValues: {
      tax: orgData?.salesTax,
      paymentType: "",
      customerPhone: "",
      notes: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      tax: Yup.string().matches(
        /^(0|[1-9]\d*)(\.\d{2})?$/,
        "Please enter valid amount upto 2 decimals.",
      ),
      paymentType: Yup.string().required("Please select payment type"),
      customerPhone: Yup.string()
        .matches(/^(0|[1-9]\d*)(\.\d+)?$/, "Please enter valid phone number")
        .notRequired(),
    }),
    onSubmit: (values) => {
      if (
        values.customerPhone.trim().length === 0 &&
        PAYMENT_TYPES_NEEDING_PHONE_NUMBERS.includes(
          values.paymentType as PAYMENT_TYPE,
        )
      ) {
        formik.setFieldError(
          "customerPhone",
          "Phone number is required for this payment type.",
        );
        return;
      }
      if (
        searchCouponText.length > 0 &&
        (values.customerPhone.length === 0 || !values.paymentType)
      ) {
        formik.setFieldError(
          "customerPhone",
          "Phone number and payment type required for coupon.",
        );
        return;
      }

      if (searchCouponText.length > 0 && !appliedCoupon) {
        formik.setFieldError("customerPhone", "This coupon cannot be applied.");
        return;
      }
      setCouponSearchText("");

      mutateAsync({
        amount: toFixed(itemTotal),
        saleDate: moment().format("YYYY-MM-DD"),
        category: items?.[0].title ?? "Scan Order - unknown",
        products: items.map((item) => item._id),
        tax: values.tax ? toFixed(values.tax) : 0.0,
        phone: `${areaCode}${values.customerPhone}`,
        paymentType: values.paymentType,
        type: "FASTFOOD",
        total: toFixed(grandTotal),
        coupons: couponData ? [+couponData.id] : [],
        notes: values.notes,
        pointsTotal,
        paymentNonce: uuidv4(),
        terminalId,
        storeCreditsTotal,
      })
        .then((resp: ICreateSaleResponse) => {
          if (resp.paymentStatus === "COMPLETED") {
            formik.resetForm();
            onOK();
          } else {
          }
        })
        .catch((err: AxiosError) => {
          if (err.status === 403) {
            messageError("You have exceeded your subscription for orders.");
          } else {
            messageError("Something went wrong! Please try again.");
          }
        });
    },
  });

  const {
    data: storeCreditsData,
    error: storeCreditDataError,
    refetch: refetchStoreCredits,
  } = useGetCustomerStoreCredits(
    id,
    `${areaCode}${formik.values.customerPhone}`,
    false,
  );

  const storeCreditValue = useMemo(() => {
    if (storeCreditDataError) {
      return 0;
    }
    if (storeCreditsData) {
      return storeCreditsData.total;
    }
    return 0;
  }, [storeCreditsData, storeCreditDataError]);

  const storeCreditsTotal = useMemo(() => {
    if (storeCreditValue > 0) {
      if (applyStoreCredits) {
        if (itemTotal > storeCreditValue) {
          return storeCreditValue;
        }
        return storeCreditValue - itemTotal;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }, [applyStoreCredits, itemTotal, storeCreditValue]);

  const grandTotal = useMemo(() => {
    return calculateProductTotal(
      items,
      formik.values.tax,
      undefined,
      undefined,
      storeCreditsTotal,
    );
  }, [items, formik.values.tax, storeCreditsTotal]);

  const handleSearchCoupon = () => {
    if (searchCouponText.length > 0) {
      refetch();
    }
  };

  const handleCustomerStoreCreditsSearch = () => {
    if (formik.values.customerPhone.length > 0) {
      refetchStoreCredits();
    }
  };

  const handleCancel = () => {
    if (formik.values.paymentType === "CARD") {
      cancelSaleAsync({})
        .then(onCancel)
        .catch(() => {});
    } else {
      onCancel();
    }
  };

  return (
    <Modal
      title="Checkout"
      open={open}
      onOk={() => formik.submitForm()}
      onCancel={handleCancel}
      okButtonProps={{
        disabled: items.length === 0,
        loading: isPending,
      }}
      cancelButtonProps={{
        loading: isCancelPending,
      }}
    >
      {notification}

      {!!isPending && <PaymentPendingLottieLoading />}

      {!isPending && (
        <form onSubmit={formik.handleSubmit}>
          <div className="divColumn">
            <div className="my-20">
              <List
                dataSource={items}
                itemLayout="horizontal"
                bordered
                renderItem={(item, index) => (
                  <List.Item key={index}>
                    <List.Item.Meta
                      avatar={<Avatar src={item.cover} />}
                      title={<span>{item.title}</span>}
                      description={toAmountDisplay(item.price?.value)}
                    />

                    <Flex gap={20}>
                      <DeleteOutlined
                        style={{ color: "red" }}
                        onClick={() => onRemoveItems(item)}
                      />
                    </Flex>
                  </List.Item>
                )}
              />
            </div>
            <Flex className="divSpread">
              <Flex vertical>
                <Flex gap={10} className="divAlignItemsOnly">
                  <strong className="infoMsg">Total</strong>
                  <h3>{toAmountDisplay(itemTotal)} </h3>
                </Flex>

                <Flex gap={10} className="divAlignItemsOnly">
                  <strong className="infoMsg">Tax</strong>
                  <Input
                    {...formik.getFieldProps("tax")}
                    style={{ width: 70 }}
                  />
                </Flex>
                <ErrorPanel message={formik.errors.tax} />
              </Flex>

              <Flex gap={20} className="divAlignItemsOnly">
                <h1>{toAmountDisplay(grandTotal)} </h1>
              </Flex>
            </Flex>

            <Flex vertical gap={10} className="my-20">
              <Flex vertical gap={10} className="my-20">
                <span>Coupon Last 4</span>
                <Input.Search
                  allowClear
                  value={searchCouponText}
                  onChange={(e) => setCouponSearchText(e.target.value)}
                  onSearch={() => handleSearchCoupon()}
                />
                {couponData && (
                  <Alert
                    type="success"
                    message={
                      <Flex vertical gap={5}>
                        <span>
                          {couponData.monetaryType} coupon: {couponData.value}{" "}
                          with min bill {couponData.minAmount}{" "}
                        </span>
                      </Flex>
                    }
                  />
                )}
                {couponData && !!appliedCoupon && (
                  <Alert
                    type="success"
                    message={<strong>Points: {pointsTotal}</strong>}
                  />
                )}
                {couponData && !appliedCoupon && (
                  <Alert type="error" message={"Unable to apply."} closable />
                )}
                {error && (
                  <Alert type="error" message={"Coupon not found."} closable />
                )}
              </Flex>

              <Flex vertical gap={10}>
                <span>Select Payment Type</span>
                <Select
                  id="payment-type-select"
                  value={formik.values.paymentType}
                  onChange={(value) =>
                    formik.setFieldValue("paymentType", value)
                  }
                  options={paymentOptions}
                />
                <ErrorPanel message={formik.errors.paymentType} />
              </Flex>

              <Flex vertical gap={10}>
                <span>Customer Phone</span>
                <Input.Search
                  prefix={areaCode}
                  onSearch={() => handleCustomerStoreCreditsSearch()}
                  {...formik.getFieldProps("customerPhone")}
                />

                {storeCreditsData && (
                  <Flex gap={15}>
                    <Checkbox
                      checked={applyStoreCredits}
                      onChange={(e) => setApplyStoreCredits(e.target.checked)}
                    />
                    <span>
                      Apply Store Credits:{" "}
                      <strong>{storeCreditsData?.total}</strong>
                    </span>
                  </Flex>
                )}

                <ErrorPanel message={formik.errors.customerPhone} />
              </Flex>

              <Flex vertical gap={10}>
                <span>Notes</span>
                <Input.TextArea
                  maxLength={200}
                  {...formik.getFieldProps("notes")}
                />
              </Flex>
            </Flex>
          </div>
        </form>
      )}
    </Modal>
  );
}
