import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  useGetOrganizationById,
  useGetProfileOnOrganization,
} from "../api/api.get";
import { IOrganization, IProfileDetails } from "../types/types";

export function useProfile() {
  const { id } = useParams();
  const { data: profile, isLoading: isProfileLoading } =
    useGetProfileOnOrganization(id);

  const { data: organization, isLoading: isOrgLoading } =
    useGetOrganizationById(id);
  const [orgData, setOrgData] = useState<IOrganization>();
  const [profileData, setProfileData] = useState<IProfileDetails>();

  useEffect(() => {
    if (organization && !isOrgLoading) {
      setOrgData(organization);
    }
  }, [organization, isOrgLoading]);

  useEffect(() => {
    if (profile && !isProfileLoading) {
      setProfileData(profile);
    }
  }, [profile, isProfileLoading]);

  return {
    profileType: profileData?.type,
    isVendor: orgData?.vendorStatus !== null,
    isLoading: isOrgLoading || isProfileLoading,
    profile: profileData,
  };
}
