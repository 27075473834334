import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from "./components/header/header.component";
import LandingPage from "./pages/landing.page";
import AuthProvider from "./providers/auth.provider";
import { AuthenticatedRoutes } from "./routes";
import "./styles/button.styles.css";
import FooterComponent from "./components/footer.component";
import RegisterPage from "./pages/register.page";
import LoginPage from "./pages/login.page";
import InvoiceReceiptPublicView from "./pages/organizations/invoices/invoice.receipt.public-view";
import { Helmet } from "react-helmet-async";

const Main = () => {
  return (
    <div className={`app`}>
      <Helmet>
        <title>ExpenseHut - Best POS System</title>
        <meta
          name="description"
          content="Simplify sales and customer management with ExpenseHut."
        />
      </Helmet>
      <BrowserRouter>
        {/*
        Do not move auth provider from here.
        The Mobile header component needs this.
        */}
        <AuthProvider>
          <Header />
          <Routes>
            <Route path="/register/verify" element={<RegisterPage />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/receipts" element={<InvoiceReceiptPublicView />} />
            <Route path="/" element={<LandingPage />} />
            <Route path="*" element={<AuthenticatedRoutes />} />
          </Routes>
        </AuthProvider>
        <FooterComponent />
      </BrowserRouter>
    </div>
  );
};

export default Main;
