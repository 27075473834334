import { LockOutlined } from "@ant-design/icons";
import { Button, Empty, Flex, Input, Table, Tabs, TabsProps } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useCreateEmployeePII } from "../../../api/api";
import { useGetEmployeePII, useGetPayrolls } from "../../../api/api.get";
import { useNotifications2 } from "../../../components/notifications/notification";
import { useProfile } from "../../../hooks/useProfile";
import { IPayroll } from "../../../types/types";
import ViewPayStubModal from "./view-paystub.modal";

export default function PayrollHomEmployeePage() {
  const { id } = useParams();

  const [payrollId, setPayrollId] = useState<number>();

  const { data: payrolls, isLoading } = useGetPayrolls(id);

  const cycleColumns = [
    {
      title: "id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Statement Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (value: string) => (
        <div>{dayjs(value).format("DD MMM YYYY")}</div>
      ),
    },
    {
      title: "",
      key: "operation",
      width: 100,
      render: (row: IPayroll) => (
        <div>
          {row.id && (
            <Button
              type="link"
              color="danger"
              onClick={() => setPayrollId(+row.id)}
            >
              View
            </Button>
          )}
        </div>
      ),
    },
  ];

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Pay Stubs",
      children: (
        <div>
          {payrolls && (
            <>
              {payrolls.length > 0 && (
                <Table
                  loading={isLoading}
                  dataSource={payrolls}
                  columns={cycleColumns}
                />
              )}
              {payrolls.length === 0 && (
                <Empty description="No pay statements generated yet." />
              )}
            </>
          )}
        </div>
      ),
    },
    {
      key: "2",
      label: "My Info",
      children: <MyInfoTab />,
    },
  ];

  const onChange = (key: string) => {
    console.log(key);
  };

  return (
    <div style={{ marginBottom: 100 }} className="body divColumn p-40 flex-1">
      <ViewPayStubModal
        open={!!payrollId}
        onCancel={() => setPayrollId(undefined)}
        payrollId={payrollId!}
      />
      <div className="title">Payroll</div>

      <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
    </div>
  );
}

function MyInfoTab() {
  const { id } = useParams();
  const { profileType, profile } = useProfile();

  const { data: employeePII } = useGetEmployeePII(
    id,
    profile?.id,
    profileType === "employee",
  );

  const [isEditMode, setIsEditMode] = useState(false);

  const { mutateAsync } = useCreateEmployeePII(id, profile?.id);
  const { notification, notifyError, notifySuccess } = useNotifications2();
  const [piiText, setPiiText] = useState("");

  useEffect(() => {
    if (employeePII) {
      setPiiText(employeePII.panLast4 ? `XXXXX${employeePII.panLast4}` : "");
      setIsEditMode(false);
    }
  }, [employeePII]);

  const handleSavePII = () => {
    mutateAsync({ pan: piiText })
      .then(() => {
        notifySuccess({
          description: "PII updated successfully",
          title: "Success",
        });
        setIsEditMode(false);
      })
      .catch(() => {
        notifyError("Failed to update PII");
      });
  };

  return (
    <div className="p-20">
      {notification}

      <div className="my-20">
        <span className="infoMsg">
          This is required by your employer for payroll purposes.
        </span>
      </div>

      {isEditMode && (
        <Flex gap={20}>
          <Input
            size="large"
            onChange={(e) => setPiiText(e.target.value)}
            style={{ width: 300 }}
            prefix={<LockOutlined />}
            placeholder="Enter your PAN number"
          />

          <Button type="primary" onClick={handleSavePII}>
            Save
          </Button>
          <Button type="link" onClick={() => setIsEditMode(false)}>
            Cancel
          </Button>
        </Flex>
      )}

      {!isEditMode && (
        <Flex gap={20}>
          <Input
            size="large"
            style={{ width: 300 }}
            disabled
            value={`XXXXXX${employeePII?.panLast4}`}
            prefix={<LockOutlined />}
            placeholder="Enter your PAN number"
          />

          <Button type="link" onClick={() => setIsEditMode(true)}>
            Edit
          </Button>
        </Flex>
      )}

      <div className="my-20">
        <span className="infoMsg">
          <LockOutlined /> This information is encrypted and stored. Only you
          and your employer can see it.
        </span>
      </div>
    </div>
  );
}
