import { PropsWithChildren, createContext, useContext } from "react";
import LocalizedStrings, { LocalizedStringsMethods } from "react-localization";

export interface ILocaleContext {
  strings: IStrings;
}

export interface IStrings extends LocalizedStringsMethods {
  register: string;
  landingPageTitle1: string;
  landingPageTitle1Subtitle: string;
  landingAllBusinessAllSize: string;
  landingAllBusinessAllSizeSubtitle: string;
}

let strings: IStrings = new LocalizedStrings({
  en: {
    register: "Sign up",
    landingPageTitle1: "Run your business at lowest cost!",
    landingPageTitle1Subtitle:
      "ExpenseHut is the all-in-one business platform designed to simplify sales, customer management, and rewards. With a powerful POS system, AI-driven product recommendations, and built-in CRM, we help businesses grow smarter.",
    landingAllBusinessAllSize: "For all businesses.",
    landingAllBusinessAllSizeSubtitle:
      "Designed for all businesses & all industries.",
  },
  hi: {
    register: "पंजीकरण",
    landingPageTitle1: "न्यूनतम लागत पर अपना व्यवसाय चलाएँ!",
    landingPageTitle1Subtitle: "अपने लाभ पर ध्यान दें. बाकी हम पर छोड़ दो.",
    landingAllBusinessAllSize: "सभी व्यवसाय. सभी आकार. कोई भी उद्योग.",
    landingAllBusinessAllSizeSubtitle:
      "किसी भी उद्योग के लिए सभी आकार के सभी व्यवसायों के लिए डिज़ाइन किया गया।",
  },
  ta: {
    register: "பதிவு",
    landingPageTitle1: "உங்கள் வணிகத்தை குறைந்த செலவில் நடத்துங்கள்!",
    landingPageTitle1Subtitle:
      "உங்கள் லாபத்தில் கவனம் செலுத்துங்கள். மீதியை எங்களிடம் விடுங்கள்.",
    landingAllBusinessAllSize:
      "அனைத்து வணிகங்கள். அனைத்து அளவுகள். எந்த தொழில்.",
    landingAllBusinessAllSizeSubtitle:
      "எந்தவொரு தொழிற்துறைக்கும் அனைத்து அளவிலான அனைத்து வணிகங்களுக்கும் வடிவமைக்கப்பட்டுள்ளது.",
  },
});

const LocalizationContext = createContext<ILocaleContext>({
  strings,
});

export const useLocalizationContext = () => useContext(LocalizationContext);

export default function LocalizationProvider(props: PropsWithChildren) {
  return (
    <LocalizationContext.Provider value={{ strings }}>
      {props.children}
    </LocalizationContext.Provider>
  );
}
