import { Button, Image, Tooltip } from "antd";
import { useNavigate, useParams } from "react-router-dom";

export default function KitchenComponent() {
  const navigate = useNavigate();
  const { id } = useParams();

  return (
    <Tooltip title="Kitchen">
      <Button
        type="text"
        id="kitchen-btn"
        icon={<Image src={"/assets/icons/kitchen.png"} preview={false} />}
        onClick={() => navigate(`/organizations/${id}/kitchen`)}
      ></Button>
    </Tooltip>
  );
}
