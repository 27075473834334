import { PlusCircleTwoTone } from "@ant-design/icons";
import { Button, Flex, Input, Popconfirm, Select, Typography } from "antd";
import { useFormik } from "formik";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { useSupplyChainCreateStockRequest } from "../../../../api/api";
import { useGetVendors } from "../../../../api/api.get";
import BreadCrumbComponent from "../../../../components/breadcrumb.component";
import ErrorPanel from "../../../../components/error.panel.component";
import { useNotifications2 } from "../../../../components/notifications/notification";
import { ProductQuantityRowItem } from "../../../../components/product-quantity.component";
import {
  IProduct,
  IProductQuanityRowItem,
  IVendor,
  STOCKREQUEST_STATUS,
} from "../../../../types/types";
import { validateItems } from "../../../../utils/products.utils";
import AddProductsModal from "../../products/add-products-modal";
const { Title } = Typography;

export default function AddStockRequestPage() {
  const [stockRowItems, setStockRowItems] = useState<IProductQuanityRowItem[]>(
    [],
  );

  const { id } = useParams();

  const { data: vendorsData } = useGetVendors(id);
  const [showAddProduct, setShowAddProduct] = useState<boolean>(false);

  const { notification, notifyError, notifySuccess } = useNotifications2();
  const { mutateAsync, isPending } = useSupplyChainCreateStockRequest(id);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      vendorId: "",
      items: [],
      notes: "",
    },
    validationSchema: Yup.object({
      vendorId: Yup.string().required("Please select a vendor"),
      items: Yup.array()
        .min(1, "Please select item.")
        .required("Please add items and quantity"),
      notes: Yup.string(),
    }),
    onSubmit: async (values) => {
      const result = validateItems(values.items);

      if (!result) {
        notifyError("One or more items are invalid.");
        return;
      }

      mutateAsync({
        ...values,
        status: STOCKREQUEST_STATUS.SAVED,
      })
        .then(() => {
          formik.resetForm();
          setStockRowItems([]);
          navigate(`/organizations/${id}/stocks`);
          notifySuccess({
            title: "Success",
            description: "Stocks requested successfully.",
          });
        })
        .catch(() => {
          notifyError(
            "Whoops! Unable to submit request. Please try after sometime.",
          );
        });
    },
  });

  const handleAddStockRowItem = () => {
    setStockRowItems([...stockRowItems, { product: null, quantity: 0 }]);
  };

  const handleRemoveItem = (index: number) => {
    const _items = [...stockRowItems];
    _items.splice(index, 1);
    setStockRowItems([..._items]);
  };

  useEffect(() => {
    formik.setFieldValue("items", stockRowItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stockRowItems]);

  const handleOnQuantityChange = (index: number, quantity: number) => {
    const items = [...stockRowItems];
    const item = items[index];
    const newItem = {
      ...item,
      quantity,
    };
    items.splice(index, 1, newItem);
    setStockRowItems(items);
  };

  const handleOnProductChange = (index: number, product: IProduct) => {
    const items = [...stockRowItems];
    const item = items[index];
    const newItem = {
      ...item,
      product,
    };
    items.splice(index, 1, newItem);
    setStockRowItems(items);
  };

  return (
    <div className="p-20">
      <BreadCrumbComponent
        items={[
          {
            title: "Home",
            url: `/organizations/${id}`,
          },
          {
            title: "Stocks",
            url: `/organizations/${id}/stocks`,
          },
        ]}
      />

      <div style={{ marginBottom: 100 }} className="card divColumn">
        {notification}
        <AddProductsModal
          open={showAddProduct}
          onCancel={() => setShowAddProduct(false)}
        />
        <div className="divSpread divAlignItemsOnly">
          <Title level={3}>New Stock Request</Title>

          <Flex gap={10}>
            <Popconfirm
              title={"Are you sure you want to submit?"}
              onConfirm={() => formik.submitForm()}
            >
              <Button
                type="primary"
                loading={isPending}
                disabled={Object.keys(formik.errors).length > 0}
              >
                Create
              </Button>
            </Popconfirm>
          </Flex>
        </div>

        <form onSubmit={formik.handleSubmit}>
          <Flex gap={20} vertical className="my-40">
            <Flex gap={10} vertical className="mb-20">
              <strong>Order Date</strong>
              <span className="infoMsg">{moment().format("DD MMM YYYY")}</span>
            </Flex>

            <Flex gap={10} vertical className="mb-20">
              <strong>Vendor</strong>
              <Select
                options={vendorsData?.map((vendor: IVendor) => {
                  return {
                    label: vendor.name,
                    value: vendor.id,
                  };
                })}
                onSelect={(_, option: any) =>
                  formik.setFieldValue("vendorId", option.value)
                }
              />
            </Flex>

            <Title level={4}>Products</Title>

            <Flex gap={20} vertical className="mb-40">
              <Flex gap={20} vertical>
                {stockRowItems.map(
                  (item: IProductQuanityRowItem, index: number) => {
                    return (
                      <ProductQuantityRowItem
                        onAddProduct={() => setShowAddProduct(true)}
                        onLoading={() => {}}
                        key={index}
                        item={item}
                        index={index}
                        onRemoveItem={(key) => handleRemoveItem(key)}
                        onQuantityChange={handleOnQuantityChange}
                        onProductChange={handleOnProductChange}
                        onProductFocussedOut={() => {}}
                        hidePrice
                      />
                    );
                  },
                )}
              </Flex>

              {formik.errors && (
                <ErrorPanel message={formik.errors.items as string} />
              )}

              <Button
                icon={<PlusCircleTwoTone />}
                onClick={() => handleAddStockRowItem()}
              ></Button>
            </Flex>

            <Flex gap={10} vertical>
              <Input.TextArea
                placeholder="Notes..."
                {...formik.getFieldProps("notes")}
              />
            </Flex>
          </Flex>
        </form>
      </div>
    </div>
  );
}
