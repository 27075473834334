import { Button, Table, Typography } from "antd";
import moment from "moment";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useGetNotifications } from "../../../api/api.get";
import ViewNotificationModal from "./view-notification.modal";

const { Title } = Typography;

export default function NotificationsHomePage() {
  const { id } = useParams();

  const { data: notifications, refetch } = useGetNotifications(id);
  const [viewNotificationId, setViewNotificationId] = useState<string>();

  const columns = [
    {
      title: "Message",
      dataIndex: "message",
      key: "message",
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (value: string) => (
        <span>{moment(value).format("DD MMM YYYY hh:mm A")}</span>
      ),
    },
    {
      title: "",
      key: "operationView",
      width: 100,
      render: (row: any) => (
        <Button type="link" onClick={() => setViewNotificationId(row.id)}>
          View
        </Button>
      ),
    },
  ];

  return (
    <div
      style={{ marginBottom: 100 }}
      className="card divColumn mx-40 my-40 flex-1"
    >
      <ViewNotificationModal
        notificationId={viewNotificationId}
        open={!!viewNotificationId}
        onCancel={() => {
          setViewNotificationId(undefined);
          refetch();
        }}
      />

      <div className="divSpread divAlignItemsOnly mt-0">
        <Title level={3}>Notifications</Title>
      </div>
      <Table
        dataSource={notifications}
        columns={columns}
        rowClassName={(record) =>
          record.acknowledge === false ? "" : "infoMsg"
        }
      />
    </div>
  );
}
