import { Button, Popconfirm, Select, Table, Typography } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  usePostKitchenItemMarkProgress,
  usePostKitchenItemMarkReady,
  usePostKitchenItemSetAssignee,
} from "../../../api/api";
import { useDeleteKitchenItem } from "../../../api/api.delete";
import {
  useGetKitchenItems,
  useGetOrganizationEmployeesPublicView,
} from "../../../api/api.get";
import EmptyComponent from "../../../components/empty.component";
import { useNotifications2 } from "../../../components/notifications/notification";
import {
  IKitchenItem,
  IOrganizationSale,
  IUserPublic,
} from "../../../types/types";
import AddExpenseModal from "../sales/add-sales-modal";
import ViewSalesModal from "../sales/view-sales-modal";

const { Title } = Typography;

export default function KitchenHomePage() {
  const { id } = useParams();
  const { data: kitchenItems, isLoading, refetch } = useGetKitchenItems(id);
  const [isAdd, setIsAdd] = useState<boolean>(false);
  const [viewableSalesObj, setViewableSalesObj] = useState<IOrganizationSale>();

  const { mutateAsync: deleteAsync } = useDeleteKitchenItem(id!!);

  const { data: employeesPublicData } =
    useGetOrganizationEmployeesPublicView(id);

  const { mutateAsync: markItemAsReadyAsync } = usePostKitchenItemMarkReady(id);

  const { mutateAsync: setAssigneeAsync } = usePostKitchenItemSetAssignee(id);

  const { mutateAsync: markItemAsProgressAsync } =
    usePostKitchenItemMarkProgress(id);

  const { notification, notifyError } = useNotifications2();

  const handleDelete = (row: IKitchenItem) => {
    deleteAsync({ itemId: row.id }).catch(console.error);
  };

  useEffect(() => {
    const id = setInterval(() => refetch(), 5000);

    return () => clearInterval(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleMarkReady = (row: IKitchenItem) => {
    markItemAsReadyAsync({ itemId: row.id }).catch(console.error);
  };

  const handleMarkProgress = (row: IKitchenItem) => {
    markItemAsProgressAsync({ itemId: row.id }).catch(console.error);
  };

  const handleAssignItem = (row: IKitchenItem, username: string) => {
    setAssigneeAsync({ itemId: row.id, assignee: username }).catch(() =>
      notifyError("Whoops! Please try again."),
    );
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Item",
      dataIndex: "productName",
      key: "productName",
    },
    {
      title: "Time Added",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (value: number) => <span>{dayjs(value).format("hh:mm A")}</span>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (value: string) => (
        <span style={{ color: value === "READY" ? "green" : "grey" }}>
          {value}
        </span>
      ),
    },
    {
      title: "Last Updated",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (value: number) => (
        <span>{value ? dayjs(value).format("hh:mm A") : ""}</span>
      ),
    },
    {
      title: "Chef",
      key: "updatedAt",
      render: (value: IKitchenItem) => (
        <UsernameComponent
          item={value}
          employees={employeesPublicData}
          onChange={(item: IKitchenItem, username: string) => {
            handleAssignItem(item, username);
          }}
        />
      ),
    },
    {
      title: "",
      key: "operation",
      width: 100,
      render: (row: IKitchenItem) => {
        if (row.status === "PROGRESS") {
          return (
            <Button onClick={() => handleMarkReady(row)} type="link">
              Mark Ready
            </Button>
          );
        }
        return (
          <Button onClick={() => handleMarkProgress(row)} type="link">
            Mark Progress
          </Button>
        );
      },
    },
    {
      title: "",
      key: "operation",
      width: 100,
      render: (row: any) => (
        <Popconfirm
          title="Delete"
          description="Are you sure to delete this order?"
          onConfirm={() => handleDelete(row)}
          okText="Yes"
          cancelText="No"
        >
          <Button danger type="text" color="danger">
            Delete
          </Button>
        </Popconfirm>
      ),
    },
  ];

  return (
    <div style={{ marginBottom: 100 }} className="divColumn mx-40 my-40">
      {notification}
      <AddExpenseModal open={isAdd} onCancel={() => setIsAdd(false)} />
      <ViewSalesModal
        open={!!viewableSalesObj}
        onCancel={() => setViewableSalesObj(undefined)}
        sales={viewableSalesObj}
      />

      <div className="divSpread divAlignItemsOnly mt-0">
        <Title level={3}>Kitchen Orders</Title>
      </div>

      {!isLoading && kitchenItems?.length === 0 && (
        <EmptyComponent title="No items pending." />
      )}
      {(isLoading || (kitchenItems && kitchenItems?.length > 0)) && (
        <Table dataSource={kitchenItems} columns={columns} />
      )}
    </div>
  );
}

function UsernameComponent({
  item,
  employees,
  onChange,
}: {
  item: IKitchenItem;
  employees?: IUserPublic[];
  onChange: (item: IKitchenItem, username: string) => void;
}) {
  const [employeesSelectOption, setEmployeesSelectOption] = useState<any[]>([]);

  useEffect(() => {
    if (employees) {
      setEmployeesSelectOption(
        employees.map((e) => {
          return { label: e.firstName ?? e.email, value: e.username };
        }),
      );
    }
  }, [employees]);

  const [assignee, setAssignee] = useState<string>();

  useEffect(() => {
    if (item.assignee) {
      setAssignee(item.assignee);
    }
  }, [item]);

  const handleSetAssigneeLocal = (username: string) => {
    setAssignee(username);
    onChange(item, username);
  };

  return (
    <Select
      style={{ width: 150 }}
      options={employeesSelectOption}
      value={assignee}
      onChange={(username) => handleSetAssigneeLocal(username)}
    />
  );
}
