import moment from "moment";

export default function FooterComponent() {
  return (
    <div className="footer p-16">
      <div className="footer-copyright">
        {" "}
        &copy; {moment().format("YYYY")} business.expensehut.com{" "}
      </div>
      <div className="divCenterAlign my-5" style={{ gap: 40, fontSize: 10 }}>
        <div className="smallText">
          <a
            href="https://help.expensehut.com/about"
            target="_blank"
            rel="noreferrer"
            style={{ color: "white", fontSize: 13 }}
          >
            About Us
          </a>
        </div>

        <div>
          <a
            href="https://help.expensehut.com/terms"
            target="_blank"
            rel="noreferrer"
            style={{ color: "white", fontSize: 13 }}
          >
            Terms
          </a>
        </div>

        <div>
          <a
            href="https://help.expensehut.com/privacy"
            target="_blank"
            rel="noreferrer"
            style={{ color: "white", fontSize: 13 }}
          >
            Privacy Policy
          </a>
        </div>
      </div>
    </div>
  );
}
