import { ReloadOutlined } from "@ant-design/icons";
import { Button, Flex, Input, Popconfirm, Select, Skeleton, Table } from "antd";
import Alert from "antd/es/alert/Alert";
import Title from "antd/es/typography/Title";
import { useFormik } from "formik";
import moment from "moment";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";
import { useExecuteCRM } from "../../../api/api";
import {
  useGetCRMById,
  useGetCRMRuns,
  useGetOrganizationById,
} from "../../../api/api.get";
import { useUpdateCRM } from "../../../api/api.update";
import ErrorPanel from "../../../components/error.panel.component";
import { useMessage } from "../../../components/notifications/message";
import { useNotifications2 } from "../../../components/notifications/notification";
import { capitalize } from "../../../utils/functions";

export default function ViewCRMPage() {
  const { id, cid } = useParams();

  const { data: orgData } = useGetOrganizationById(id);

  const { data: crmData, isLoading } = useGetCRMById(id, cid);

  const {
    data: crmRunsData,
    isLoading: isRunsLoading,
    refetch: refreshRuns,
  } = useGetCRMRuns(id, cid);

  const [needsSave, setNeedsSave] = useState<boolean>(false);

  const { notifySuccess, notification, notifyError } = useNotifications2();

  const {
    messageSuccess,
    notification: notificationMessage,
    messageError,
  } = useMessage();

  const navigate = useNavigate();

  const { mutateAsync, isPending } = useUpdateCRM(id, crmData?.id);

  const { mutateAsync: executeCrmAsync, isPending: isExecutePending } =
    useExecuteCRM(id, crmData?.id);

  const handleExecuteCrm = () => {
    executeCrmAsync({ nonce: uuidv4() })
      .then(() =>
        notifySuccess({
          title: "Success",
          description: "Messages successfully sent",
        }),
      )
      .catch(() => notifyError());
  };

  const formik = useFormik({
    initialValues: {
      category: crmData?.allCustomers ? "all" : "phone",
      name: crmData?.name ?? "",
      message: crmData?.smsMsg ?? "",
      customerPhoneNumbers: crmData?.customerPhoneNumbers?.join(",") ?? "",
      notes: "",
    },
    validate(values) {
      setNeedsSave(true);
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string().required("Please enter name"),
      category: Yup.string().required("Please select a category"),
      message: Yup.string().required("Please enter message"),
    }),
    onSubmit: async (values) => {
      mutateAsync({
        name: values.name,
        smsMsg: values.message,
        allCustomers: values.category === "all",
        notes: values.notes,
        customerPhoneNumbers: values.customerPhoneNumbers
          ?.split(",")
          .map((p: string) => p.trim()),
      })
        .then(() => {
          setNeedsSave(false);
          messageSuccess(`Campaign successfully saved`);
        })
        .catch(() => {
          messageError();
        });
    },
  });

  return (
    <div className="body p-40 flex-1">
      {notification}
      {notificationMessage}

      <div></div>

      <div className="divSpread divAlignItemsOnly">
        <Button
          type="link"
          onClick={() => navigate(`/organizations/${id}/crm`)}
        >
          Back
        </Button>
        <Title level={3}>{capitalize(crmData?.name)}</Title>
        <div></div>
      </div>

      <Flex gap={50} vertical>
        <form onSubmit={formik.handleSubmit} className="card p-40">
          <Skeleton loading={isLoading}>
            <Flex gap={30} vertical style={{ padding: 20 }}>
              <div className="divSpread">
                <div>
                  <Select
                    showSearch
                    style={{ minWidth: 200 }}
                    placeholder="Select a category"
                    optionFilterProp="label"
                    value={formik.values.category}
                    onChange={(_, option) =>
                      formik.setFieldValue("category", (option as any).value)
                    }
                    options={[
                      { label: "All Customers", value: "all" },
                      {
                        label: "Customer phone",
                        value: "phone",
                      },
                    ]}
                  />{" "}
                  <ErrorPanel message={formik.errors.name} />
                </div>
                <div>
                  <Flex gap={10}>
                    <Button
                      loading={isPending}
                      onClick={formik.submitForm}
                      type="link"
                      disabled={Object.keys(formik.errors).length > 0}
                    >
                      Save
                    </Button>
                  </Flex>
                </div>
              </div>

              <Popconfirm
                title={"Are you ready to send to your customers?"}
                onConfirm={handleExecuteCrm}
              >
                <Button
                  type="primary"
                  disabled={needsSave}
                  loading={isExecutePending}
                >
                  Run Campaign
                </Button>
              </Popconfirm>

              <div>
                <h4>Name</h4>
                <Input placeholder="Name" {...formik.getFieldProps("name")} />
                <ErrorPanel message={formik.errors.name} />
              </div>
              <div>
                <h4>SMS Text</h4>
                <Flex vertical gap={10} className="my-20">
                  <strong className="infoMsg mediumText">Preview:</strong>

                  <Alert
                    type="info"
                    message={
                      capitalize(orgData?.name) + ": " + formik.values.message
                    }
                    className="mediumText"
                  />
                </Flex>

                <div className="divRight infoMsg mediumText p-10">
                  {formik.values.message.length}
                </div>

                <Input.TextArea
                  maxLength={200}
                  placeholder="SMS Text. For ex: '20% discount on all dresses and shoes.'"
                  {...formik.getFieldProps("message")}
                />
                <ErrorPanel message={formik.errors.message} />
              </div>

              {formik.values.category === "phone" && (
                <div>
                  <h4>Customer Phone Numbers</h4>

                  <Input.TextArea
                    maxLength={2000}
                    placeholder="Comma separated customer phone numbers with area code. For ex: +1234567890, +0987654321"
                    {...formik.getFieldProps("customerPhoneNumbers")}
                  />
                </div>
              )}
              <div className="mb-40">
                <h4>Notes</h4>
                <Input placeholder="Notes" {...formik.getFieldProps("notes")} />
              </div>
            </Flex>
          </Skeleton>
        </form>

        <div className="card p-40 my-20">
          <div className="divSpread">
            <h3>Runs</h3>

            <Button
              icon={<ReloadOutlined />}
              onClick={() => refreshRuns()}
            ></Button>
          </div>

          <Table
            dataSource={crmRunsData}
            columns={columns}
            loading={isRunsLoading}
          />
        </div>
      </Flex>
    </div>
  );
}

const columns = [
  {
    title: "Run Date",
    dataIndex: "executeDate",
    key: "executeDate",
    render: (value: string) => (
      <span>{moment.utc(value).local().format("DD MMM YYYY hh:mm A")}</span>
    ),
  },
  {
    title: "Customer Reach",
    dataIndex: "customerCount",
    key: "customerCount",
  },
  {
    title: "Customer Phone",
    dataIndex: "customerPhoneNumbers",
    key: "customerPhoneNumbers",
    render: (value: string[]) => <span>{value.slice(0, 5).join(",")}</span>,
  },
];
