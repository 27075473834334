import { Descriptions, Flex, Modal } from "antd";
import moment from "moment";
import { useParams } from "react-router-dom";
import { useGetNotificationById } from "../../../api/api.get";
import { capitalizeFromSnakeCase } from "../../../utils/functions";

export default function ViewNotificationModal({
  open,
  onCancel,
  notificationId,
}: {
  notificationId?: string;
  open: boolean;
  onCancel: () => void;
}) {
  const { id } = useParams();
  const { data: notificationData } = useGetNotificationById(id, notificationId);

  return (
    <Modal title="" open={open} width={700} footer={null} onCancel={onCancel}>
      <Flex gap={30} vertical style={{ padding: 20 }}>
        <Descriptions layout="vertical" title="Notification Details" bordered>
          <Descriptions.Item label="Type">
            {capitalizeFromSnakeCase(notificationData?.module)}
          </Descriptions.Item>
          <Descriptions.Item label="Message">
            {notificationData?.message}
          </Descriptions.Item>
          <Descriptions.Item label="Date">
            {moment(notificationData?.createdAt).format("DD MMM YYYY hh:mm A")}
          </Descriptions.Item>
        </Descriptions>

        <strong>Need help?</strong>
        <Flex gap={10} vertical>
          <div>
            Check out -{" "}
            <a
              href="https://help.expensehut.com/business/stocks"
              target="_blank"
              rel="noreferrer"
            >
              Help docs
            </a>
          </div>
          <div>(or)</div>
          Email: help@expensehut.com
        </Flex>
      </Flex>
    </Modal>
  );
}
