import { Alert, Avatar, Button, Flex, Input, Modal, Select } from "antd";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useUpdatePhone, useUpdateProfile } from "../../api/api.update";
import ErrorPanel from "../../components/error.panel.component";
import { getCountryPhoneCode, ROUTES } from "../../utils/constants";
import { useNotifications2 } from "../../components/notifications/notification";
import {
  usePostTriggerSMSVerification,
  usePostVerifySMSVerification,
} from "../../api/api";
import { useMessage } from "../../components/notifications/message";

export default function CreateCustomer() {
  const navigate = useNavigate();
  const { notifyError, notification } = useNotifications2();
  const [overwriteMode, setOverwriteMode] = useState<boolean>(false);

  const { mutateAsync, isPending } = useUpdateProfile();
  const { mutateAsync: updatePhoneAsync, isPending: isPhoneUpatePending } =
    useUpdatePhone();
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      phone: "",
      country: "",
      areaCode: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("Please enter first name"),
      lastName: Yup.string().required("Please enter last name"),
      country: Yup.string().required("Please select your country"),
      phone: Yup.string()
        .matches(/^[0-9]+$/, "Please enter only numbers.")
        .required("Please enter phone."),
    }),
    onSubmit: async (values) => {
      mutateAsync({
        firstName: values.firstName,
        lastName: values.lastName,
        countryCode: values.country,
      })
        .then(() => {
          updatePhoneAsync({ phone: `${values.areaCode}${values.phone}` })
            .then(() => {
              navigate(ROUTES.DASHBOARD);
            })
            .catch((err) => {
              if (err.response.status === 409) {
                setOverwriteMode(true);
              } else {
                notifyError(
                  "Whoops!! Unable to update phone number this time.",
                );
              }
            });
        })
        .catch(() => {
          notifyError("Whoops!! Unable to update this time.");
        });
    },
  });

  useEffect(() => {
    if (formik.values.country) {
      formik.setFieldValue(
        "areaCode",
        getCountryPhoneCode(formik.values.country),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.country]);

  return (
    <div className="mt-40">
      {notification}
      <OverwritePhoneModal
        areaCode={formik.values.areaCode}
        phone={formik.values.phone}
        open={overwriteMode}
        onClose={() => setOverwriteMode(false)}
        onOK={() => {
          navigate(ROUTES.DASHBOARD);
        }}
      />
      <div className={`divCenterAlign divColumn p-32`}>
        <div className="card p-60">
          <div className="w-100">
            <div className="mb-80">
              <div className="title mb-10">Profile</div>
            </div>

            <div className="divCenterAlign my-0">
              <Avatar
                style={{ width: 56, height: 56 }}
                src={"/assets/icons/man.svg"}
              />
            </div>

            <div className="mt-80">
              <form onSubmit={formik.handleSubmit}>
                <Flex className="my-40" vertical>
                  <Flex vertical>
                    <h4>First Name</h4>
                    <div className="divColumn">
                      <Input
                        maxLength={100}
                        {...formik.getFieldProps("firstName")}
                      />

                      <ErrorPanel message={formik.errors.firstName} />
                    </div>
                  </Flex>

                  <Flex vertical className="mb-40">
                    <h4>LastName</h4>

                    <div className="divColumn">
                      <Input {...formik.getFieldProps("lastName")} />
                      <ErrorPanel message={formik.errors.lastName} />
                    </div>
                  </Flex>

                  <Flex vertical>
                    <h4>Country</h4>

                    <Select
                      onChange={(value) =>
                        formik.setFieldValue("country", value)
                      }
                      style={{ width: "auto" }}
                      options={[
                        { value: "CAN", label: "Canada" },
                        { value: "IND", label: "India" },
                        { value: "USA", label: "United States Of America" },
                      ]}
                    />
                    <ErrorPanel message={formik.errors.country} />
                  </Flex>

                  <Flex vertical>
                    <h4>Phone</h4>

                    <Input
                      prefix={formik.values.areaCode}
                      maxLength={15}
                      {...formik.getFieldProps("phone")}
                    />
                    <ErrorPanel message={formik.errors.phone} />
                  </Flex>
                </Flex>

                <Button
                  type="primary"
                  onClick={formik.submitForm}
                  loading={isPending || isPhoneUpatePending}
                  disabled={Object.keys(formik.errors).length > 0}
                >
                  Save
                </Button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function OverwritePhoneModal({
  open,
  onClose,
  onOK,
  phone,
  areaCode,
}: {
  phone: string;
  areaCode: string;
  open: boolean;
  onClose: () => void;
  onOK: () => void;
}) {
  const [verifyMode, setVerifyMode] = useState<boolean>(false);
  const { messageSuccess, notification } = useMessage();
  const [code, setCode] = useState("");
  const [error, setError] = useState<string>();

  const { mutateAsync, isPending: isTriggerSMSLoading } =
    usePostTriggerSMSVerification();

  const {
    mutateAsync: verifyPasscodeAsync,
    isPending: isVerifyPasscodeLoading,
  } = usePostVerifySMSVerification();

  useEffect(() => {
    setVerifyMode(false);
  }, [open]);

  const handleTriggerSMS = () => {
    setError(undefined);

    mutateAsync({ phone })
      .then(() => {
        messageSuccess("One time passcode sent successfully.");
        setVerifyMode(true);
      })
      .catch((err) => {
        if (err.response.status === 403) {
          setError("You have exceeded the number of attempts");
        } else {
          setError("Whoops! Unable to perform request.");
        }
      });
  };

  const handleVerifySMS = () => {
    setError(undefined);
    verifyPasscodeAsync({ phone: `${areaCode}${phone}`, code: +code })
      .then(() => {
        messageSuccess("One time passcode verified and phone number updated.");
        setVerifyMode(true);
        onClose();
      })
      .catch((err) => {
        setError("Whoops! Invalid passcode. Please try again.");
      });
  };

  const handleClose = () => {
    setVerifyMode(false);
    onClose();
  };

  return (
    <Modal
      title="Phone number in use"
      open={open}
      onOk={onOK}
      onCancel={onClose}
      okText="Confirm"
      cancelText="Cancel"
      okButtonProps={{
        disabled: false,
        loading: false,
      }}
      footer={null}
    >
      {notification}
      <p>
        We found out this phone number is already in use. If this is yours,
        would you like to confirm it through an One Time Passcode (OTP)?
      </p>
      <p>
        <p>
          <Input prefix={areaCode} disabled={true} value={phone} />
        </p>

        {error && <Alert type="error" message={error} />}

        {!verifyMode && (
          <Flex>
            <Flex gap={5} className="my-40">
              <Button type="text" onClick={handleClose}>
                Cancel
              </Button>

              <Button
                loading={isTriggerSMSLoading}
                type="primary"
                onClick={handleTriggerSMS}
              >
                Send OTP
              </Button>
            </Flex>
          </Flex>
        )}

        {verifyMode && (
          <Flex vertical className="my-40">
            <Input
              placeholder="Enter OTP..."
              id="verify-otp-input"
              onChange={(e) => setCode(e.target.value)}
              value={code}
            />

            <Flex gap={5} className="my-40">
              <Button type="text" onClick={handleClose}>
                Cancel
              </Button>

              <Button
                loading={isVerifyPasscodeLoading}
                type="primary"
                disabled={!code}
                onClick={handleVerifySMS}
              >
                Verify
              </Button>
            </Flex>
          </Flex>
        )}
      </p>
    </Modal>
  );
}
