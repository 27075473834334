import { useFeatureIsOn } from "@growthbook/growthbook-react";
import { Button, Flex, Input } from "antd";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import * as Yup from "yup";
import {
  useRegisterMutation,
  useRegisterSSOMutation,
  useRegisterVerify,
} from "../api/api";
import ErrorPanel from "../components/error.panel.component";
import TermsConditionsComponent from "../components/other/TermsConditionsComponent";
import StatusMessageComponent from "../components/status-message.component";
import { useLayoutContext } from "../providers/layout.provider";
import { IStatusMsg } from "../types/types";
import {
  EXHUT_BIZ_LOCAL_STORAGE_TOKEN,
  FeatureFlags,
  ROUTES,
} from "../utils/constants";
import { getRecaptchaKey, isRecaptchaEnabled } from "../utils/functions";
import { downloadAndLoadGSIScript } from "../utils/lib.helper";

export default function RegisterPage() {
  const { mutateAsync, isPending: isLoading } = useRegisterMutation();
  const { mutateAsync: verifyRegisterAsync, isPending: isVerifyPending } =
    useRegisterVerify();
  const { mutateAsync: registerWithSSO } = useRegisterSSOMutation();
  const { isMobile } = useLayoutContext();
  const [statusMsg, setStatusMsg] = useState<IStatusMsg>();
  const [recaptchaVerified, setRecaptchaVerified] =
    useState(!isRecaptchaEnabled);
  const isEnabled = useFeatureIsOn(FeatureFlags.ENABLE_BUSINESS);
  const [searchParams] = useSearchParams();

  const candidateId = new URLSearchParams(searchParams).get("cid");
  const regKey = new URLSearchParams(searchParams).get("key");

  useEffect(() => {
    if (candidateId && regKey) {
      verifyRegisterAsync({ cid: candidateId, regkey: regKey })
        .then(() => {
          navigate(ROUTES.LOGIN + "?register=complete");
        })
        .catch(() => {
          setStatusMsg({
            type: "error",
            text: "Sorry! The verification link seem to have expired. Please re-register again.",
          });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [candidateId, regKey]);

  const navigate = useNavigate();

  useEffect(() => {
    downloadAndLoadGSIScript()
      .then(() => {
        (window as any).google.accounts.id.initialize({
          client_id: process.env.REACT_APP_EXHUT_GOOGLE_CLIENT_ID,
          callback: (response: any) => {
            registerWithSSO({ token: response.credential })
              .then((resp) => {
                localStorage.setItem(EXHUT_BIZ_LOCAL_STORAGE_TOKEN, resp.sign);
                navigate(ROUTES.HOME);
              })
              .catch((e) => {
                let msg = "Whoops! Something went wrong. Please try again.";
                if (e.response?.data?.status === 409) {
                  msg = e.response?.data?.message;
                }
                setStatusMsg({ type: "error", text: msg });
              });
          },
        });
        (window as any).google.accounts.id.renderButton(
          document.querySelector(".sso-signin"),
          { theme: "dark", size: "medium", text: "signup_with" }, // customization attributes
        );
      })
      .catch((err) => console.error(err));
  }, [registerWithSSO, navigate]);

  const formik = useFormik({
    initialValues: {
      password: "",
      email: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .min(8, "Minimum of 8 characters")
        .required("Password is required"),
      email: Yup.string()
        .matches(
          /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/,
          "Please enter valid email address",
        )
        .required("Please enter email."),
    }),
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: async (values) => {
      mutateAsync(values)
        .then(() => {
          navigate(ROUTES.LOGIN + "?register=success");
        })
        .catch((e) => {
          let msg = "Whoops! Something went wrong. Please try again.";
          if (
            e.response?.data?.message ===
            "An account already exists with this email."
          ) {
            msg = e.response?.data?.message;
          }
          setStatusMsg({ type: "error", text: msg });
        });
    },
  });

  const setLoginReady = (e: any) => {
    setRecaptchaVerified(true);
  };

  return (
    <div className={`splitright ${isMobile ? "mobile-splitright my-20" : ""}`}>
      {!isEnabled && (
        <div
          className="p-20 divColumn divCenterAlign"
          style={{ backgroundColor: "lightgrey" }}
        >
          <h2>Spring 2025!</h2>
          <img src="/assets/landingpage/billboard.png" alt="coming-soon-logo" />
        </div>
      )}

      {isEnabled && (
        <div className={`${isMobile ? "divColumn" : "divFlex my-40"}`}>
          <div className="divCenterAlign w-100 divColumn">
            <div className="divCenterAlign">
              <h2 style={{ color: "#2c82ff" }}>Register</h2>
            </div>

            <div style={{ minHeight: 40 }} className="mx-20">
              <StatusMessageComponent
                onClose={() => setStatusMsg(undefined)}
                message={statusMsg}
              />
              {(isLoading || isVerifyPending) && (
                <div className="divCenterAlign"></div>
              )}
            </div>

            <form
              onSubmit={formik.handleSubmit}
              className="card"
              style={{ width: "50%" }}
            >
              <Flex vertical gap={50}>
                <Flex gap={20} vertical>
                  <strong className="font-larger">Email</strong>

                  <div>
                    <Input
                      style={{ width: 300 }}
                      {...formik.getFieldProps("email")}
                    />
                    <ErrorPanel message={formik.errors.email} />
                  </div>
                </Flex>

                <Flex gap={20} vertical>
                  <strong className="font-larger">Password</strong>

                  <div>
                    <Input
                      style={{ width: 300 }}
                      type="password"
                      {...formik.getFieldProps("password")}
                    />
                    <ErrorPanel message={formik.errors.password} />
                  </div>
                </Flex>
              </Flex>

              <div className="divCenterAlign mt-40">
                {isRecaptchaEnabled && (
                  <ReCAPTCHA
                    sitekey={getRecaptchaKey()}
                    onChange={setLoginReady}
                  />
                )}
              </div>

              <Flex vertical gap={30} className="divCenterAlign mt-40">
                <Button
                  onClick={formik.submitForm}
                  style={{ width: 300 }}
                  type="primary"
                  disabled={!recaptchaVerified}
                >
                  Register{" "}
                </Button>
              </Flex>

              <div className="divColumn">
                <div className="my-20">
                  <Link to="/login">Already registered?</Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}

      <hr />

      <TermsConditionsComponent />
    </div>
  );
}
