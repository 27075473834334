import {
  BarcodeOutlined,
  DeleteFilled,
  DeleteOutlined,
  LoadingOutlined,
  QuestionOutlined,
  StarFilled,
} from "@ant-design/icons";
import {
  AutoComplete,
  AutoCompleteProps,
  Avatar,
  Button,
  Checkbox,
  Divider,
  Flex,
  Image,
  Input,
  List,
  Modal,
  Skeleton,
  Spin,
  Tag,
  Tooltip,
} from "antd";
import { TextAreaRef } from "antd/es/input/TextArea";
import Select, { DefaultOptionType } from "antd/es/select";
import moment from "moment";
import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useDebounce } from "use-debounce";
import { v4 as uuidv4 } from "uuid";
import { useCreateOrgSale } from "../../../../api/api";
import {
  useGetCustomerStoreCredits,
  useGetOrganizationSalesById,
  useGetOrgSetting,
  useSearchCustomers,
  useSearchProducts,
  useSearchProductsByBarcode,
  useSearchRewardLevelByExternalId,
} from "../../../../api/api.get";
import ErrorPanel from "../../../../components/error.panel.component";
import { PaymentPendingLottieLoading } from "../../../../components/lottie.viewer.component";
import { useNotifications2 } from "../../../../components/notifications/notification";
import { PriceComponent } from "../../../../components/price.component";
import { useCurrency } from "../../../../hooks/useCurrency";
import { usePaymentOptions } from "../../../../hooks/usePaymentOptions";
import {
  ICreateSaleResponse,
  IOrganizationRewardLevel,
  IProduct,
} from "../../../../types/types";
import { capitalizeWithTrunc } from "../../../../utils/functions";
import { toFixed } from "../../../../utils/number.utils";
import { applyCoupons } from "../../../../utils/orders.utils";
import {
  findProductsWithInsufficientQuantitities,
  findProductsWithZeroOrNoQuantity,
} from "../../../../utils/products.utils";
import { useSalesTerminalsHook } from "../../../../hooks/useSalesTerminalsHook";

const { Search } = Input;

export default function CheckoutPage() {
  const { id } = useParams();
  const [searchText, setSearchText] = useState<string>("");
  const [searchCouponText, setCouponSearchText] = useState<string>("");
  const { data: orgSettingsData } = useGetOrgSetting(id);
  const [items, setItems] = useState<IProduct[]>([]);
  const [coupons, setCoupons] = useState<IOrganizationRewardLevel[]>([]);
  const { currency } = useCurrency();

  const [debouncedSearchText] = useDebounce(searchText, 500);
  const [debouncedSearchCouponText] = useDebounce(searchCouponText, 500);

  const productTextInputRef = useRef<TextAreaRef>(null);
  const [nameSearch, setNameSearch] = useState<boolean>(false);
  const [options, setOptions] = useState<AutoCompleteProps["options"]>([]);
  const [servicesSearchText, setServicesSearchText] = useState<string>("");
  const [debouncedProductNameSearchText] = useDebounce(servicesSearchText, 300);
  const { areaCode, toAmountDisplay } = useCurrency();
  const [customerPhoneText, setCustomerPhoneText] = useState<string>("");
  const { notification, notifyError, notifySuccess } = useNotifications2();
  const [confirmCheckout, setConfirmCheckout] = useState<boolean>(false);
  const {
    data,
    isLoading: isSearchLoading,
    error,
  } = useSearchProductsByBarcode(id, debouncedSearchText);

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const isDebug = searchParams.get("debug") === "true";

  const {
    data: couponData,
    isLoading: isSearchCouponLoading,
    error: couponError,
  } = useSearchRewardLevelByExternalId(id, debouncedSearchCouponText);

  const { data: productsByNameData } = useSearchProducts(
    id,
    debouncedProductNameSearchText,
  );
  const {
    data: customerSearchData,
    refetch: searchCustomer,
    error: customerNotFoundError,
  } = useSearchCustomers(areaCode!! + customerPhoneText, false);

  useEffect(() => {
    if (customerSearchData) {
      notifySuccess({ title: "Success", description: "Customer Found!" });
    }

    if (customerNotFoundError) {
      notifyError("Customer not found");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerNotFoundError, customerSearchData]);

  useEffect(() => {
    if (productTextInputRef.current) {
      setTimeout(() => {
        productTextInputRef.current?.focus();
      }, 500);
    }
  }, []);

  useEffect(() => {
    if (data) {
      const _items = [...items];
      _items.push(data);
      setItems(_items);
      setSearchText("");
      productTextInputRef.current?.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (couponData) {
      const _coupons = new Set(coupons);
      _coupons.add(couponData);
      setCoupons(Array.from(_coupons));
      setCouponSearchText("");
      // productTextInputRef.current?.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [couponData]);

  useEffect(() => {
    if (productsByNameData) {
      setOptions(
        productsByNameData.map((product: IProduct) => {
          return {
            value: product.title,
            title: product.title,
            id: product._id,
            price: product.price,
            cover: product.cover,
            salesTaxExcluded: product.salesTaxExcluded,
            quantity: product.quantity,
          };
        }),
      );
    }
  }, [productsByNameData]);

  const handleRemoveItems = (index: number) => {
    const _items = [...items];
    _items.splice(index, 1);
    setItems(_items);
  };

  const handleRemoveCoupons = (index: number) => {
    const _coupons = [...coupons];
    _coupons.splice(index, 1);
    setCoupons(_coupons);
  };

  const itemTotal: number = useMemo(() => {
    const total = items.reduce(
      (acc, item) => acc + (item.price?.value ?? 0),
      0,
    );
    return toFixed(total);
  }, [items]);
  const {
    points: pointsTotal,
    discount: discountTotal,
    coupon: appliedCoupon,
  } = applyCoupons(itemTotal, coupons);

  /**
   * This is a super critical function
   *
   */
  const grandTotal = useMemo(() => {
    // items can have sales tax excluded
    const orgSalesTax = orgSettingsData?.salesTax ?? 0;

    // we calculate individual item discount, since it would be easy to minus it when the item is returned.
    return items.reduce((acc, item) => {
      const individualItemDiscountPercent = toFixed(
        (item.price.value / itemTotal) * 100,
      );
      const individualItemDiscount = toFixed(
        (individualItemDiscountPercent / 100) * discountTotal,
      );

      const itemPrice = (item.price?.value ?? 0) - individualItemDiscount;

      if (item.salesTaxExcluded === true) {
        console.log(
          "Sales tax excluded for item ",
          item.title,
          "Actual price",
          item.price.value,
          " individualItemDiscount ",
          individualItemDiscount,
          " Net Price",
          itemPrice,
        );
        return acc + itemPrice;
      }
      const tax = toFixed((itemPrice * orgSalesTax) / 100);
      return acc + itemPrice + tax;
    }, 0);
  }, [items, discountTotal, orgSettingsData?.salesTax, itemTotal]);

  const handleAddItems = (option: DefaultOptionType) => {
    if (options) {
      const temp = [...items];
      temp.push({
        _id: option.id,
        title: option.title ?? "",
        price: option.price,
        createdAt: option.createdAt,
        cover: option.cover,
        salesTaxExcluded: option.salesTaxExcluded,
        quantity: option.quantity,
      });
      setItems(temp);

      setServicesSearchText("");
    }
  };

  const canSubmit =
    customerPhoneText.length > 0 &&
    /^\d+$/.test(customerPhoneText) &&
    items.length > 0;

  const handleCheckout = () => {
    const productWithZeroOrNoQuantity = findProductsWithZeroOrNoQuantity(items);

    if (productWithZeroOrNoQuantity) {
      notifyError(
        "Product " + productWithZeroOrNoQuantity?.title + " is out of stock.",
      );
      return;
    }
    const productWithInsufficientQuantity =
      findProductsWithInsufficientQuantitities(items);
    if (productWithInsufficientQuantity) {
      notifyError(
        "Product " +
          productWithInsufficientQuantity?.title +
          " has only " +
          productWithInsufficientQuantity.quantity +
          " remaining.",
      );
      return;
    }
    setConfirmCheckout(true);
  };

  return (
    <div className="p-0">
      <div className="card divFlex">
        {notification}

        <ConfirmCheckout
          items={items}
          tax={orgSettingsData?.salesTax ?? 0}
          discountTotal={discountTotal}
          pointsTotal={pointsTotal}
          customerPhone={`${areaCode}${customerSearchData?.phone ?? customerPhoneText}`}
          coupons={coupons.map((c) => +c.id)}
          itemTotal={itemTotal}
          total={grandTotal}
          open={confirmCheckout}
          onOK={() => {
            setConfirmCheckout(false);
            setCustomerPhoneText("");
            setItems([]);
          }}
          onCancel={() => setConfirmCheckout(false)}
        />
        <div className="flex-1 p-0 divColumn">
          <div className="" style={{ overflowY: "scroll", height: 400 }}>
            {isSearchLoading && <Skeleton active />}

            {!isSearchLoading && (
              <List
                itemLayout="horizontal"
                bordered
                dataSource={items}
                renderItem={(item, index) => (
                  <List.Item>
                    <List.Item.Meta
                      avatar={<Avatar src={item.cover} />}
                      title={item.title}
                      description={
                        <PriceComponent price={item.price} prefix={currency} />
                      }
                    />

                    <Flex gap={20}>
                      {item.salesTaxExcluded === true && (
                        <Tooltip title="No sales tax">
                          <StarFilled style={{ color: "orange" }} />
                        </Tooltip>
                      )}
                      {(!item.quantity || item.quantity === 0) && (
                        <Tooltip title="Out of stock">
                          <QuestionOutlined style={{ color: "orange" }} />
                        </Tooltip>
                      )}
                      <DeleteOutlined
                        style={{ color: "red" }}
                        onClick={() => handleRemoveItems(index)}
                      />
                    </Flex>
                  </List.Item>
                )}
              />
            )}
          </div>

          <div className="p-20 divColumn">
            <h4>Customer Phone</h4>

            <Search
              addonBefore={areaCode!!}
              value={customerPhoneText}
              onSearch={() => searchCustomer()}
              style={{ width: "80%" }}
              status={
                customerPhoneText?.length === 0 ||
                (customerPhoneText.length > 0 && /\D/.test(customerPhoneText))
                  ? "error"
                  : undefined
              }
              onChange={(e) => setCustomerPhoneText(e.target.value)}
            />

            {customerSearchData && (
              <div className="mt-20">
                <Tag color="green">
                  {customerSearchData?.firstName && customerSearchData?.lastName
                    ? `${customerSearchData?.firstName} ${customerSearchData?.lastName}`
                    : customerSearchData?.email}
                </Tag>
              </div>
            )}
          </div>
        </div>

        <Divider type="vertical" style={{ height: 600 }} />
        <div className="flex-1 divColumn">
          <div>
            <div className="mb-20 divSpread">
              <Tooltip title="Orders Home">
                <Button
                  type="text"
                  icon={
                    <Image src="/assets/icons/home-order.svg" preview={false} />
                  }
                  onClick={() => navigate(`/organizations/${id}/sales`)}
                ></Button>
              </Tooltip>

              <Button
                type="primary"
                onClick={() => handleCheckout()}
                disabled={!canSubmit}
              >
                Checkout
              </Button>
            </div>

            <Flex gap={5} className="divColumn">
              {!nameSearch && (
                <Flex vertical className="w-">
                  <Input.TextArea
                    ref={productTextInputRef}
                    allowClear
                    disabled={isSearchLoading}
                    onChange={(e) => setSearchText(e.target.value)}
                    value={searchText}
                    placeholder="Place cursor here and scan.."
                  />
                  {error && (
                    <ErrorPanel
                      message={"Item not found with code " + searchText}
                    />
                  )}
                </Flex>
              )}
              {nameSearch && (
                <AutoComplete
                  size="large"
                  options={options}
                  value={servicesSearchText}
                  onSelect={(_, option) => handleAddItems(option)}
                  onSearch={(text) => setServicesSearchText(text)}
                  placeholder="Search by name..."
                  notFoundContent={"No results found."}
                />
              )}

              <Flex className="divRight">
                {nameSearch && (
                  <Button type="link" onClick={() => setNameSearch(false)}>
                    <BarcodeOutlined />
                  </Button>
                )}
                {!nameSearch && (
                  <Button type="link" onClick={() => setNameSearch(true)}>
                    Search by name
                  </Button>
                )}
              </Flex>
            </Flex>
          </div>

          <Divider />

          <div
            style={{
              overflowX: "auto",
              overflowY: "hidden",
            }}
          >
            <div className="divRight">
              <Skeleton loading={isSearchCouponLoading}>
                <Input
                  style={{ width: 200 }}
                  onChange={(e) => setCouponSearchText(e.target.value)}
                  value={searchCouponText}
                  placeholder="Coupons.."
                />
              </Skeleton>
            </div>
            <div className="divRight my-5">
              {couponError && <ErrorPanel message={"Coupon not found."} />}
            </div>

            <div
              style={{
                display: "flex",
                width: 400,
              }}
            >
              {coupons.map(
                (coupon: IOrganizationRewardLevel, index: number) => (
                  <div
                    key={index}
                    className="card"
                    style={{
                      minWidth: 0,
                      minHeight: 0,
                      paddingTop: 10,
                      paddingRight: 10,
                      paddingLeft: 10,
                      paddingBottom: 10,
                      width: 100,

                      marginLeft: 10,
                      marginRight: 10,
                      border: `1px solid ${
                        appliedCoupon?.id === coupon.id
                          ? "limegreen"
                          : "lightgrey"
                      }`,
                    }}
                  >
                    <div
                      className="divSpread"
                      style={{
                        paddingTop: 10,
                        paddingBottom: 10,
                        color: "darkslategray",
                      }}
                    >
                      <BarcodeOutlined />
                      <DeleteFilled
                        onClick={() => handleRemoveCoupons(index)}
                      />
                    </div>
                    <span className="mediumText">
                      <Tooltip title={coupon.name}>
                        {capitalizeWithTrunc(coupon.name, 50)}
                      </Tooltip>
                    </span>
                  </div>
                ),
              )}
            </div>
          </div>

          <Divider />

          <div
            className="w-100 divColumn right-align"
            style={{ marginRight: 20, marginBottom: 0 }}
          >
            <span className="my-10" style={{ fontSize: 20 }}>
              Total : <strong>{itemTotal ?? 0}</strong>
            </span>

            <div className="divSpread">
              <span className="my-0" style={{ fontSize: 20 }}>
                Points : <strong>{pointsTotal ?? 0}</strong>
              </span>

              <span className="my-0" style={{ fontSize: 20 }}>
                Discount :{" "}
                <strong>{toAmountDisplay(discountTotal ?? 0)}</strong>
              </span>
            </div>

            <span className="my-10" style={{ fontSize: 20 }}>
              Sales Tax: <strong>{orgSettingsData?.salesTax ?? 0}%</strong>
              {isDebug && (
                <span>
                  [
                  {toFixed(
                    ((orgSettingsData?.salesTax ?? 0) / 100) *
                      (itemTotal - discountTotal),
                  )}
                  ]
                </span>
              )}
            </span>
            <span style={{ fontSize: 65, fontWeight: "bold" }}>
              {toAmountDisplay(grandTotal)}
            </span>
            {isSearchLoading && (
              <Spin indicator={<LoadingOutlined spin />} size="large" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

function ConfirmCheckout({
  open,
  total,
  itemTotal,
  onCancel,
  onOK,
  items,
  tax,
  customerPhone,
  coupons,
  discountTotal,
  pointsTotal,
}: {
  pointsTotal?: number;
  discountTotal?: number;
  coupons: number[];
  customerPhone: string;
  tax: number;
  items: IProduct[];
  itemTotal: number;
  total: number;
  open: boolean;
  onOK: (paymentType?: string) => void;
  onCancel: () => void;
}) {
  const { id } = useParams();
  const { mutateAsync, isPending } = useCreateOrgSale(id);
  const [paymentType, setPaymentType] = useState<string>();
  const { toAmountDisplay } = useCurrency();
  const [canSubmit, setCanSubmit] = useState<boolean>(true);
  const { notifyError } = useNotifications2();
  const [pendingOrderId, setPendingOrderId] = useState<string | undefined>();
  const intervalId = useRef<NodeJS.Timer | undefined>();
  const { data: orderData, refetch: refetchSalesById } =
    useGetOrganizationSalesById(id, pendingOrderId);
  const { paymentOptions } = usePaymentOptions();
  const { terminalId } = useSalesTerminalsHook();
  const [applyStoreCredits, setApplyStoreCredits] = useState<boolean>(false);
  const {
    data: storeCreditsData,
    error: storeCreditDataError,
    refetch: refetchStoreCredits,
  } = useGetCustomerStoreCredits(id, `${customerPhone}`, false);

  useEffect(() => {
    if (open) {
      setCanSubmit(true);
      refetchStoreCredits();
    }
  }, [open, refetchStoreCredits]);

  useEffect(() => {
    setCanSubmit(!!paymentType);
  }, [paymentType, open]);

  useEffect(() => {
    if (open) {
      setPendingOrderId(undefined);
    }
  }, [open]);

  useEffect(() => {
    if (!!pendingOrderId) {
      intervalId.current = setInterval(() => refetchSalesById(), 5000);
      setTimeout(() => {
        clearInterval(intervalId.current);
      }, 120000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pendingOrderId]);

  useEffect(() => {
    if (orderData && orderData.payments?.[0].status === "COMPLETED") {
      setPendingOrderId(undefined);
      clearInterval(intervalId.current);
      onOK();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderData]);

  const storeCreditValue = useMemo(() => {
    if (storeCreditDataError) {
      return 0;
    }
    if (storeCreditsData) {
      return storeCreditsData.total;
    }
    return 0;
  }, [storeCreditsData, storeCreditDataError]);

  const storeCreditsTotal = useMemo(() => {
    if (storeCreditValue > 0) {
      if (applyStoreCredits) {
        if (itemTotal > storeCreditValue) {
          return storeCreditValue;
        }
        return storeCreditValue - itemTotal;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }, [applyStoreCredits, itemTotal, storeCreditValue]);

  const grandTotal = useMemo(() => {
    return total - storeCreditsTotal;
  }, [total, storeCreditsTotal]);

  const handleCheckout = () => {
    mutateAsync({
      amount: toFixed(itemTotal),
      saleDate: moment().format("YYYY-MM-DD"),
      category: items?.[0].title ?? "Scan Order - unknown",
      products: items.map((item) => item._id),
      tax,
      phone: customerPhone,
      paymentType,
      coupons: coupons,
      discountTotal: discountTotal,
      total: toFixed(grandTotal),
      type: "RETAIL",
      pointsTotal: pointsTotal,
      paymentNonce: uuidv4(),
      terminalId,
    })
      .then((resp: ICreateSaleResponse) => {
        if (resp.paymentStatus === "COMPLETED") {
          setPendingOrderId(undefined);

          onOK();
        } else {
          setPendingOrderId(resp.id);
        }
      })
      .catch((err) => {
        if (err.status === 403) {
          notifyError("You have exceeded your subscription for orders.");
        } else {
          notifyError("Something went wrong! Please try again.");
        }
      });
  };

  return (
    <div>
      <Modal
        title="Confirm Order?"
        open={open}
        onOk={handleCheckout}
        onCancel={onCancel}
        okButtonProps={{
          disabled: !canSubmit || !!pendingOrderId,
          loading: isPending,
        }}
      >
        {!!pendingOrderId && <PaymentPendingLottieLoading />}

        {!pendingOrderId && (
          <div className="divColumn">
            <Flex className="divFlex divAlignItemsOnly my-20" gap={10}>
              <strong>Total</strong>
              <h1>{toAmountDisplay(grandTotal)}</h1>
            </Flex>

            <Flex>
              {storeCreditsData && (
                <Flex gap={15}>
                  <Checkbox
                    checked={applyStoreCredits}
                    onChange={(e) => setApplyStoreCredits(e.target.checked)}
                  />
                  <span>
                    Apply Store Credits:{" "}
                    <strong>{storeCreditsData?.total}</strong>
                  </span>
                </Flex>
              )}
            </Flex>

            <Flex vertical gap={10} className="my-40">
              <span>Select Payment Type</span>

              <Select
                id="first-time-select"
                defaultValue=""
                onChange={(value) => setPaymentType(value)}
                options={paymentOptions}
              />
            </Flex>
          </div>
        )}
      </Modal>
    </div>
  );
}
