import { Button, Flex, Modal } from "antd";
import { useParams } from "react-router-dom";
import { useGetOrganizationById } from "../../../api/api.get";

import { WarningFilled } from "@ant-design/icons";
import Alert from "antd/es/alert/Alert";
import { Content } from "antd/es/layout/layout";
import {
  usePauseVendorRegistration,
  useRegisterAsVendor,
  useResumeVendorRegistration,
  useUnRegisterAsVendor,
} from "../../../api/api";
import { InfoIcon } from "../../../components/icon.component";
import { useNotifications2 } from "../../../components/notifications/notification";

export default function VendorSettingsPage() {
  const { id } = useParams();
  const { mutateAsync, isPending } = useRegisterAsVendor(id);
  const {
    mutateAsync: cancelVendorRegistrationAsync,
    isPending: isUnregisterPending,
  } = useUnRegisterAsVendor(id);

  const {
    mutateAsync: pauseVendorRegistrationAsync,
    isPending: isPausePending,
  } = usePauseVendorRegistration(id);

  const { mutateAsync: resumeVendorRegistrationAsync } =
    useResumeVendorRegistration(id);

  const [modal, contextHolder] = Modal.useModal();

  const { data: orgData } = useGetOrganizationById(id);

  const { notifySuccess, notification, notifyError } = useNotifications2();

  const handleRegisterAsVendor = async () => {
    mutateAsync({})
      .then(() => {
        notifySuccess({
          title: "Success",
          description: "Vendor registration successfull!",
        });
      })
      .catch(() => notifyError());
  };

  const handleCancelVendorSubscription = async () => {
    cancelVendorRegistrationAsync({})
      .then(() => {
        notifySuccess({
          title: "Success",
          description: "Vendor registration successfully cancelled!",
        });
      })
      .catch(() => notifyError());
  };

  const handlePauseVendorSubscription = async () => {
    pauseVendorRegistrationAsync({})
      .then(() => {
        notifySuccess({
          title: "Success",
          description: "Vendor status paused!!",
        });
      })
      .catch(() => notifyError());
  };

  const handleResumeVendorSubscription = async () => {
    resumeVendorRegistrationAsync({})
      .then(() => {
        notifySuccess({
          title: "Success",
          description: "Vendor status resumed!",
        });
      })
      .catch(() => notifyError());
  };

  const deleteConfirm = () => {
    modal.confirm({
      title: "Confirm",
      icon: <WarningFilled />,
      content:
        "You will lose your vendorship status permanently. While you can re-create it later, your active stock requests will be cancelled immediately. Your merchants will have to re-add as their vendor again.",
      okText: "Confirm",
      okButtonProps: { loading: isUnregisterPending },
      onOk: handleCancelVendorSubscription,
    });
  };

  const pauseConfirm = () => {
    modal.confirm({
      title: "Confirm",
      icon: <WarningFilled />,
      content:
        "This will temporarily pause your vendor status. Merchants will not be able to send new stock requests. Existing stock requests will be cancelled. You can resume anytime later.",
      okText: "Confirm",
      okButtonProps: { loading: isPausePending },
      onOk: handlePauseVendorSubscription,
    });
  };

  return (
    <div id="org-tax-settings-container" className="p-40 flex-1">
      {notification}
      {contextHolder}
      <div className={`divCenterAlign divColumn p-32`}>
        <Content
          className="p-20"
          style={{ minWidth: "40%", boxShadow: "3px 1px 5px 5px #e6ddd6" }}
        >
          <div className="w-100">
            <div className="mb-80">
              <div className="title mb-10">Vendor Settings</div>
            </div>

            <div className="mt-80 mb-60">
              <Flex className="divCenterAlign" gap={10}>
                <InfoIcon title="Can be used to show in invoices when shared to customers." />
                <p className="infoMsg mediumText">
                  Register as vendor to accept stock requests.{" "}
                </p>
              </Flex>

              <p className="infoMsg mediumText">
                Manage multiple customers at the same time.
              </p>
              <Flex vertical gap={40}>
                {orgData?.vendorStatus === null && (
                  <Button
                    loading={isPending}
                    type="primary"
                    onClick={() => handleRegisterAsVendor()}
                  >
                    Register As Vendor
                  </Button>
                )}

                {orgData?.vendorStatus !== null && (
                  <Flex vertical gap={20}>
                    <Flex className="divCenterAlign">
                      {orgData?.vendorStatus === true && (
                        <Button
                          loading={isPending}
                          type="link"
                          style={{ color: "orange" }}
                          onClick={pauseConfirm}
                        >
                          Pause Subscription
                        </Button>
                      )}

                      {orgData?.vendorStatus === false && (
                        <Flex
                          className="my-40 divCenterAlign"
                          vertical
                          gap={10}
                        >
                          <Alert
                            type="warning"
                            message="Vendor status: Inactive"
                          />

                          <Button
                            loading={isPending}
                            type="link"
                            style={{ color: "limegreen" }}
                            onClick={handleResumeVendorSubscription}
                          >
                            Resume Subscription
                          </Button>
                        </Flex>
                      )}
                    </Flex>

                    <Button
                      loading={isPending}
                      type="link"
                      danger
                      onClick={deleteConfirm}
                    >
                      Cancel Vendor Subscription
                    </Button>
                  </Flex>
                )}
              </Flex>
            </div>
          </div>
        </Content>
      </div>
    </div>
  );
}
