import { Flex, Input, Modal, Select } from "antd";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { useCreateCRM } from "../../../api/api";
import ErrorPanel from "../../../components/error.panel.component";
import { useNotifications } from "../../../components/notifications/notification";

export default function AddCRMModal({
  open,
  onCancel,
}: {
  open: boolean;
  onCancel: () => void;
}) {
  const { id } = useParams();

  const { notifySuccess, notification, notifyError } = useNotifications({
    title: "Success",
    subtitle: "Expense created successfully.",
  });

  const { mutateAsync, isPending } = useCreateCRM(id);

  const formik = useFormik({
    initialValues: {
      category: "",
      name: "",
      message: "",
      customerPhoneNumbers: "",
      notes: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please enter name"),
      category: Yup.string().required("Please select a category"),
      message: Yup.string().required("Please enter message"),
    }),
    onSubmit: async (values) => {
      mutateAsync({
        name: values.name,
        smsMsg: values.message,
        allCustomers: values.category === "all",
        notes: values.notes,
        customerPhoneNumbers: values.customerPhoneNumbers
          ?.split(",")
          .map((p: string) => p.trim()),
      })
        .then(() => {
          notifySuccess();
          formik.resetForm();
          onCancel();
        })
        .catch(() => {
          notifyError();
        });
    },
  });

  console.log(formik.errors);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Modal
        title="Add Campaign"
        open={open}
        onOk={formik.submitForm}
        onCancel={onCancel}
        okButtonProps={{ loading: isPending }}
      >
        {notification}
        <Flex gap={30} vertical>
          <div>
            <Select
              showSearch
              style={{ minWidth: 200 }}
              placeholder="Select a category"
              optionFilterProp="label"
              value={formik.values.category}
              onChange={(_, option) =>
                formik.setFieldValue("category", (option as any).value)
              }
              options={[
                { label: "All Customers", value: "all" },
                {
                  label: "Customer phone",
                  value: "phone",
                },
              ]}
            />

            <ErrorPanel message={formik.errors.category} />
          </div>

          <div className="my-0">
            <Input placeholder="Name..." {...formik.getFieldProps("name")} />
          </div>

          <div>
            <Input.TextArea
              placeholder="SMS Text. For ex: '20% discount on all dresses and shoes.'"
              {...formik.getFieldProps("message")}
            />
            <ErrorPanel message={formik.errors.message} />
          </div>

          {formik.values.category === "phone" && (
            <div>
              <Input.TextArea
                placeholder="Comma separated customer phone numbers with area code. For ex: +1234567890, +0987654321"
                {...formik.getFieldProps("customerPhoneNumbers")}
              />
            </div>
          )}
          <div className="mb-40">
            <Input placeholder="Notes" {...formik.getFieldProps("notes")} />
          </div>
        </Flex>
      </Modal>
    </form>
  );
}
