import { Button, Divider, Flex, Image, Modal, Select, Table, Tag } from "antd";
import { useMemo, useRef, useState } from "react";
import generatePDF, { Margin } from "react-to-pdf";
import { useReactToPrint } from "react-to-print";

import Alert from "antd/es/alert/Alert";
import { Buffer } from "buffer";
import moment from "moment-timezone";
import { useParams } from "react-router-dom";
import { useSendInvoice } from "../../../api/api";
import {
  useGetInvoiceEmailLatest,
  useGetOrganizationById,
  useGetOrgSetting,
} from "../../../api/api.get";
import { useMessage } from "../../../components/notifications/message";
import { useCurrency } from "../../../hooks/useCurrency";
import { IInvoice, IProductQuanityRowItem } from "../../../types/types";
import { toReadableDate } from "../../../utils/date.utils";
import { capitalize, getProductCurrencySymbol } from "../../../utils/functions";
import { toFixedDisplay } from "../../../utils/number.utils";

type EmailFormat = "inline" | "pdf";

export default function ViewInvoiceModal({
  open,
  onCancel,
  invoice,
  paymentsTotal,
  action,
}: {
  action?: "email" | "download" | "print" | null;
  paymentsTotal?: number;
  invoice?: IInvoice;
  open: boolean;
  onCancel: () => void;
}) {
  const { id } = useParams();
  const { toAmountDisplay } = useCurrency();
  const { data: orgData } = useGetOrganizationById(id);
  const [emailFormat, setEmailFormat] = useState<EmailFormat>("pdf");
  const { data: orgSettingsData } = useGetOrgSetting(id);

  const { mutateAsync, isPending } = useSendInvoice(id, invoice?.id);

  const { data: invoiceEmail } = useGetInvoiceEmailLatest(
    id,
    invoice?.id,
    action === "email",
  );
  const { messageError, messageSuccess, notification } = useMessage();

  const contentRef = useRef(null);
  const handlePrint = useReactToPrint({
    contentRef,
  });

  const handleDownload = () =>
    generatePDF(contentRef, { filename: `invoice_${invoice?.externalId}.pdf` });

  const handleEmail = async (format: EmailFormat) => {
    if (format === "pdf") {
      const fileName = `invoice_${invoice?.externalId}.pdf`;
      const pdf = await generatePDF(contentRef, {
        filename: fileName,
        method: "build",
        page: { margin: Margin.LARGE },
      });
      const blob = pdf.output("arraybuffer");
      const base64 = Buffer.from(blob).toString("base64");

      mutateAsync({
        fileName,
        pdfContent: base64,
        customMsg: "Here is your invoice.",
        mode: "EMAIL",
        emailFormat: "PDF",
      })
        .then(() => {
          messageSuccess("Invoice emailed successfully.");
        })
        .catch(() => {
          messageError("Whoops! Something went wrong. ");
        });
    }
  };

  const columns = [
    {
      title: "Item",
      key: "title",
      render: (row: IProductQuanityRowItem) => (
        <span>{capitalize(row.product?.title)}</span>
      ),
    },
    {
      title: "Quantity",
      key: "quantity",
      render: (row: IProductQuanityRowItem) => <span>{row.quantity}</span>,
    },
    {
      title: "Price",
      render: (row: IProductQuanityRowItem) => (
        <span>
          {getProductCurrencySymbol(row.product?.price?.currency)}
          {toFixedDisplay(row.product?.price?.value)}
        </span>
      ),
    },
  ];
  const paymentColumns = [
    {
      title: "Date",
      dataIndex: "paymentDate",
      key: "paymentDate",
      render: (value: string) => <>{toReadableDate(value)}</>,
    },
    {
      title: "Amount",
      dataIndex: "total",
      key: "total",
      render: (value: number) => <>{toAmountDisplay(value)}</>,
    },
    {
      title: "Type",
      dataIndex: "paymentType",
      key: "paymentType",
    },
  ];

  const paymentStatusLabel: "PAID" | "NOT PAID" | null = useMemo(() => {
    if (paymentsTotal && invoice) {
      if (paymentsTotal > 0) {
        if (paymentsTotal >= invoice.total) {
          return "PAID";
        } else {
          return "NOT PAID";
        }
      }
      return "NOT PAID";
    }
    return "NOT PAID";
  }, [paymentsTotal, invoice]);

  return (
    <Modal open={open} onOk={onCancel} onCancel={onCancel} footer={null}>
      {notification}

      {action === "email" && (
        <div className="divSpread divAlignItemsOnly p-10">
          <div className="divColumn" style={{ gap: 10 }}>
            {!invoice?.customer?.email && (
              <Tag color="error">Customer Email missing</Tag>
            )}

            {!!invoice?.customer?.email && (
              <Flex gap={10} className="divAlignItemsOnly">
                Email as
                <Select
                  placeholder="Select format"
                  onSelect={(value) => setEmailFormat(value)}
                  value={emailFormat}
                  options={[{ value: "pdf", label: "PDF" }]}
                />
                <Button
                  type="link"
                  onClick={() => handleEmail(emailFormat)}
                  loading={isPending}
                >
                  Send
                </Button>
              </Flex>
            )}
            {invoiceEmail?.createdAt && (
              <span className="infoMsg smallText">
                Last Sent:{" "}
                {moment(invoiceEmail?.createdAt).format("DD MMM YYYY hh:mm A")}
              </span>
            )}
          </div>

          {paymentStatusLabel && (
            <div className="mt-10 divCenterAlign">
              <Alert
                type={paymentStatusLabel === "PAID" ? "success" : "error"}
                message={paymentStatusLabel}
              />
            </div>
          )}
        </div>
      )}
      {action !== "email" && (
        <div className="divRight mr-10">
          {action === "download" && (
            <Button type="link" onClick={() => handleDownload()}>
              Download
            </Button>
          )}

          {action === "print" && (
            <Button type="link" onClick={() => handlePrint()}>
              Print
            </Button>
          )}
        </div>
      )}

      <Divider />

      <Flex
        id="invoice-body"
        gap={10}
        vertical
        style={{ padding: "40px 40px" }}
        ref={contentRef}
      >
        <div>
          <div className="divSpread divAlignItemsOnly">
            <h2>{"INVOICE"}</h2>
            <div className="mediumText">#{invoice?.externalId}</div>
          </div>

          <div className="divSpread my-10">
            <div className="divColumn">
              <strong>{capitalize(orgData?.name)}</strong>
              <span>{orgData?.street1}</span>
              <span>{orgData?.street2}</span>
              <span>{orgData?.city}</span>
              <span>
                {orgData?.state} {orgData?.country} {orgData?.zipCode}
              </span>
            </div>

            <div>
              <span className="mediumText">
                {moment(invoice?.createdAt).format("DD MMM YYYY")}
              </span>
            </div>
          </div>
        </div>

        <hr style={{ border: "1 solid black" }} />

        <div>
          <h4> BILL TO </h4>
          <div>
            <div className="my-10">
              <div className="divColumn">
                {(invoice?.customer?.firstName ||
                  invoice?.customer?.lastName) && (
                  <strong>
                    {capitalize(invoice?.customer?.firstName)}{" "}
                    {capitalize(invoice?.customer?.lastName)}
                  </strong>
                )}

                <span>{capitalize(invoice?.customer?.address?.street1)}</span>
                <span>{capitalize(invoice?.customer?.address?.street2)}</span>
                <span>{capitalize(invoice?.customer?.address?.city)}</span>
                <span>
                  {invoice?.customer?.address?.state && (
                    <span>
                      {capitalize(
                        invoice?.customer?.address?.state?.toUpperCase(),
                      )}
                      , {capitalize(invoice?.customer?.address?.countryCode)}
                    </span>
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className="my-5">
            <div>
              <strong>Email</strong>
              <span className="mx-10">{invoice?.customer?.email}</span>
            </div>

            <div>
              <strong>Phone</strong>
              <span className="mx-10">{invoice?.customer?.phone}</span>
            </div>
          </div>
        </div>
        <hr style={{ border: "1 solid black" }} />

        <div className="my-0">
          <Table
            bordered
            dataSource={invoice?.items}
            columns={columns}
            pagination={{ hideOnSinglePage: true }}
          />
        </div>

        <Flex gap={20} vertical className="divRight my-20">
          <div className="divRight">
            <strong>Net Total: </strong>
            <span style={{ width: 120 }}>
              {toAmountDisplay(invoice?.total)}
            </span>
          </div>
          <div className="divRight">
            <strong>Tax: </strong>
            <span style={{ width: 120 }}>{toAmountDisplay(invoice?.tax)}%</span>
          </div>

          <div className="divRight divAlignItemsOnly">
            <strong>Total:</strong>
            <strong style={{ width: 120 }}>
              {toAmountDisplay(invoice?.grossTotal)}
            </strong>
          </div>
        </Flex>
        <hr style={{ border: "1 solid black" }} />

        {invoice?.payments && invoice?.payments?.length > 0 && (
          <div className="my-0">
            <h4>Payments</h4>
            <Table
              bordered
              dataSource={invoice?.payments}
              columns={paymentColumns}
              pagination={{ hideOnSinglePage: true }}
            />
          </div>
        )}

        {invoice?.notes && (
          <div className="my-20">
            <strong className="mx-10">Notes:</strong>
            <div
              className="p-10 my-20"
              style={{ border: "1px solid lightgrey", borderRadius: 5 }}
            >
              {invoice?.notes}
            </div>
          </div>
        )}

        {!!paymentsTotal && paymentsTotal > 0 && (
          <div className="divRight">
            <strong>
              Total Paid{" "}
              <h2 className="mx-10">{toAmountDisplay(paymentsTotal)}</h2>{" "}
            </strong>
          </div>
        )}

        {invoice?.includeFooter === true && (
          <div className="my-40">
            <div
              className="my-20 p-10"
              style={{ border: "1px dashed lightgrey", borderRadius: 5 }}
            >
              <p
                style={{ color: "var(--exhut-dark-grey)" }}
                className="mediumText"
              >
                {orgSettingsData?.invoiceFooter}
              </p>
              {orgSettingsData?.invoiceFooterImage && (
                <Image src={orgSettingsData.invoiceFooterImage} width={100} />
              )}
            </div>
          </div>
        )}
      </Flex>
    </Modal>
  );
}
