import { Button, Popconfirm, Table } from "antd";
import moment from "moment";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useDeletePayment } from "../../../api/api.delete";
import { useCurrency } from "../../../hooks/useCurrency";
import { IInvoice } from "../../../types/types";
import AddPaymentModal from "./add-payment.modal";
import { useNotifications2 } from "../../../components/notifications/notification";

export default function EditInvoicePayments({
  invoiceData,
  refetchInvoiceData,
}: {
  invoiceData?: IInvoice;
  refetchInvoiceData: () => void;
}) {
  const { id } = useParams();
  const [isAddPayment, setIsAddPayment] = useState<boolean>(false);
  const { toAmountDisplay } = useCurrency();
  const { mutateAsync: deleteAsync } = useDeletePayment(id!!);
  const { notification, notifyError } = useNotifications2();

  const handleDelete = (row: any) => {
    deleteAsync({ paymentId: row.id })
      .then(() => {
        refetchInvoiceData();
      })
      .catch(() => {
        notifyError("Cannot delete non-cash payment.");
      });
  };

  const paymentColumns = [
    {
      title: "Date",
      dataIndex: "paymentDate",
      key: "paymentDate",
      render: (value: string) => <>{moment(value).format("DD MMM YYYY")}</>,
    },
    {
      title: "Amount",
      dataIndex: "total",
      key: "total",
      render: (value: number) => <>{toAmountDisplay(value)}</>,
    },
    {
      title: "Type",
      dataIndex: "paymentType",
      key: "paymentType",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "",
      key: "operation",
      width: 100,
      render: (row: any) => (
        <Popconfirm
          title="Delete"
          description="Are you sure to delete the payment?"
          onConfirm={() => handleDelete(row)}
          okText="Yes"
          cancelText="No"
        >
          <Button danger type="text" color="danger">
            Delete
          </Button>
        </Popconfirm>
      ),
    },
  ];

  return (
    <div className="my-60">
      {notification}
      <AddPaymentModal
        invoice={invoiceData}
        open={isAddPayment}
        onCancel={() => {
          refetchInvoiceData();
          setIsAddPayment(false);
        }}
      />

      <div className="divSpread divAlignItemsOnly">
        <h3>Payments</h3>

        <Button type="link" onClick={() => setIsAddPayment(true)}>
          Create Payment
        </Button>
      </div>

      <Table dataSource={invoiceData?.payments} columns={paymentColumns} />

      {/**
         * <List
        itemLayout="horizontal"
        bordered
        dataSource={invoiceData?.payments}
        renderItem={(item, index) => (
          <List.Item>
            <List.Item.Meta
              title={
                <a href="https://ant.design">
                  {toReadableDate(item.paymentDate)}
                </a>
              }
              description={"$" + item.amount}
            />

            <Flex gap={20}>
              <strong>{item.paymentType}</strong>
              <DeleteOutlined style={{ color: "red" }} onClick={() => {}} />
            </Flex>
          </List.Item>
        )}
      />
         */}
    </div>
  );
}
