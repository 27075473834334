import { Alert, Checkbox, Flex, Input, Modal, TimePicker } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { useCreateTimesheet } from "../../../api/api";
import { useGetTimeSheetsForEmployee } from "../../../api/api.get";
import ErrorPanel from "../../../components/error.panel.component";
import { toSQL } from "../../../utils/date.utils";

export default function ViewTimeSheetModal({
  open,
  day,
  onCancel,
}: {
  day?: Dayjs;
  open: boolean;
  onCancel: () => void;
}) {
  const { id } = useParams();

  const { data: timsheets, refetch } = useGetTimeSheetsForEmployee(
    id,
    day?.format("YYYY-MM-DD"),
    day?.add(2, "D").format("YYYY-MM-DD"),
  );

  const [timeMode, setTimeMode] = useState<boolean>(false);

  const { mutateAsync, error, isPending } = useCreateTimesheet(id);

  const formik = useFormik({
    initialValues: {
      hours: timsheets?.[0]?.hours ?? 0,
      startTime: timsheets?.[0]?.startTime ?? "",
      endTime: timsheets?.[0]?.endTime ?? "",
      timeSheetDate: day?.format("YYYY-MM-DD"),
      notes: timsheets?.[0]?.notes ?? "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      timeSheetDate: Yup.string().required("Please select date"),
      startTime: Yup.string().optional(),
      endTime: Yup.string().optional(),
      hours: Yup.number()
        .typeError("Please enter valid number")
        .positive("Please enter above 0")
        .test("is-decimal", "Please enter valid input", (val: any) => {
          if (val !== undefined) {
            return /^\d+(\.\d{0,1})?$/.test(val);
          }
          return true;
        })
        .min(1, "please add valid number.")
        .max(24, " You cannot enter more than 24 hours")
        .required("Please enter hours"),
    }),
    onSubmit: async (values) => {
      const diff = dayjs(values.endTime, "hh:mm a").diff(
        dayjs(values.startTime, "hh:mm a"),
        "m",
      );

      if (diff < 1) {
        window.alert("End date should be lesser than start date");
      } else {
        mutateAsync({
          ...values,
          hours: +values.hours,
          timeSheetDate: toSQL(formik.values.timeSheetDate),
        })
          .then(() => {
            formik.resetForm();
            onCancel();
          })
          .catch(console.error);
      }
    },
  });

  useEffect(() => {
    if (formik.values.timeSheetDate) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.timeSheetDate]);

  useEffect(() => {
    const startTime = formik.values.startTime;
    const endTime = formik.values.endTime;
    if (startTime && endTime) {
      const diff = dayjs(endTime, "hh:mm a").diff(
        dayjs(startTime, "hh:mm a"),
        "m",
      );
      const hours = Math.floor(diff / 60);
      const mins = diff % 60;

      formik.setFieldValue("hours", +(hours + mins / 60).toFixed(2));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.startTime, formik.values.endTime]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Modal
        title="Add Hours"
        open={open}
        onOk={formik.submitForm}
        okButtonProps={{
          disabled: isPending || Object.keys(formik.errors).length > 0,
        }}
        onCancel={() => {
          formik.resetForm();
          onCancel();
        }}
      >
        <Flex gap={20} vertical>
          <div className="divCenterAlign">
            <h4>{dayjs(formik.values.timeSheetDate).format("DD MMMM YYYY")}</h4>
          </div>

          <div className="divColumn" style={{ gap: 10 }}>
            <div className="divSpread divAlignItemsOnly">
              <Checkbox
                checked={timeMode}
                onChange={(e) => setTimeMode(!timeMode)}
              >
                Use Time{" "}
              </Checkbox>

              <strong>Start & End Time</strong>
            </div>

            <TimePicker.RangePicker
              disabled={!timeMode}
              minuteStep={30}
              secondStep={10}
              hourStep={1}
              format={"hh:mm a"}
              value={[
                formik.values.startTime
                  ? dayjs(formik.values.startTime, "hh:mm a")
                  : dayjs(),
                formik.values.endTime
                  ? dayjs(formik.values.endTime, "hh:mm a")
                  : dayjs().add(1, "hour"),
              ]}
              onChange={(_, dates) => {
                formik.setFieldValue("startTime", dates[0]);
                formik.setFieldValue("endTime", dates[1]);
              }}
            />
          </div>

          <div className="divColumn my-20" style={{ gap: 10 }}>
            <strong>Hours</strong>
            {timeMode && (
              <strong style={{ fontSize: "x-large" }}>
                {formik.values.hours}
              </strong>
            )}
            {!timeMode && (
              <Input placeholder="Hours" {...formik.getFieldProps("hours")} />
            )}
            <ErrorPanel message={formik.errors.hours} />
          </div>

          <div>
            <Input placeholder="Notes" {...formik.getFieldProps("notes")} />
          </div>

          {error && (
            <div className="my-20">
              <Alert
                type="error"
                closable
                message={
                  "Whoops! Unable to add timesheet. Please contact your employer."
                }
              />
            </div>
          )}
        </Flex>
      </Modal>
    </form>
  );
}
