import { Badge } from "antd";
import React from "react";
import { Link, useParams } from "react-router-dom";
import { useGetNewNotifications } from "../api/api.get";

export default function Notifications() {
  const { id } = useParams();

  const { data } = useGetNewNotifications(id);

  return (
    <Link to={`/organizations/${id}/notifications`} id="org-notifications">
      <Badge count={data?.count ?? 0} size={"small"}>
        <img src={"/assets/icons/bell.png"} width={20} alt="notifications" />
      </Badge>
    </Link>
  );
}
