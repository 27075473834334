export interface IOrganizationLimit {
  crm: number;
  employees: number;
  rewards: number;
  sales: number;
}

export interface IOrganizationEntitlementData {
  limit: IOrganizationLimit;
  remaining: IOrganizationLimit;
}

export interface ISalesTerminalDevice {
  connectionStatus: "connected";
  deviceId: string;
  registrationStatus: string;
  deviceNickname: string;
}

export interface ISalesTerminalData {
  devices: ISalesTerminalDevice[];
}

export interface ISalesTerminalResponse {
  data: ISalesTerminalData;
}

export interface ISalesTerminalByIdResponse {
  data: ISalesTerminalDevice;
}

export interface ICRMRun {
  crmId: number;
  id: number;
  customerCount: number;
  executeDate: string;
}

export interface ICRM {
  id: string;
  name: string;
  smsMsg: string;
  enabled: boolean;
  customerPhoneNumbers: string[];
  allCustomers: boolean;
  notes: string;
}

export interface IOrganizationSetting {
  id: string;
  entitlement: number;
  createdAt: string;
}

export interface IOrganizationSetting {
  invoiceFooter?: string;
  salesTax?: number;
  incomeTax?: number;
  invoiceFooterImage?: string;
}
export interface IStockRequest {
  createdAt: string;
  dueDate: string;
  id: string;
  items: IProductQuanityRowItem[];
  orgId: number;
  username: string;
  vendorId: number;
}

export type IStockRequestWithStatus = IStockRequest & {
  status:
    | STOCKREQUEST_STATUS.SAVED
    | STOCKREQUEST_STATUS.BLOCKED
    | STOCKREQUEST_STATUS.CANCELLED
    | STOCKREQUEST_STATUS.COMPLETED
    | STOCKREQUEST_STATUS.DELAYED
    | STOCKREQUEST_STATUS.ON_ROUTE
    | STOCKREQUEST_STATUS.PROCESSING
    | STOCKREQUEST_STATUS.SAVED
    | STOCKREQUEST_STATUS.SUBMITTED;
};

export interface IStockRequestLifecycle {
  createdAt: string;
  expectedDeliveryDate: string;
  id: string;
  notes: string;
  shippedDate: string;
  status: string;
  stockRequestId: string;
  username: string;
}

export type AdminIUser = IUser & {
  status: boolean;
};

export type IUserPublic = Pick<
  IUserSearch,
  "firstName" | "lastName" | "email" | "countryCode"
> & { username: string };

export interface IUser {
  username: string;
  sign: string;
  firstname: string;
  lastname: string;
  email: string;
  useremail: string;
  admin: boolean;
  country_code: string;
  entitlement: number;
  phone: string;
}

export type IExhutProfile = IUserSearch & {
  street1: string;
  street2?: string;
  city: string;
  state: string;
  dob: string;
};

export interface IUserSearch {
  firstName: string;
  lastName: string;
  email: string;
  userEmail: string;
  countryCode: string;
  phone: string;
}

export interface IBusinessProfile {
  createdAt: string;
  email: string;
  employment_start_date: string;
  employment_type: "salaried" | "hourly";
  id: string;
  org_id: number;
  org_name: string;
  salary: number;
  status: boolean;
  type: "admin" | "employee";
  username: string;
  entitlement?: number;
}

export interface IProfileDetails {
  countryCode: string;
  createdAt: string;
  email: string;
  employmentStartDate: string;
  employmentEndDate?: string;
  employmentType: "salaried" | "hourly";
  firstName: string;
  id: string;
  lastName: string;
  orgId: number;
  salary: number;
  status: boolean;
  type: "admin" | "employee";
  username: string;
  settings: IProfileSettings;
}

export interface IOrganizationEmployee {
  createdAt: string;
  employmentStartDate: string;
  employmentEndDate?: string;
  employmentType?: string;
  id: string;
  orgId: number;
  status: boolean;
  type: "admin" | "employee";
  username: string;
  email: string;
  salary: number;
  settings?: IProfileSettings;
}

export interface IProfile {
  countryCode?: string | null;
  firstName?: string;
  lastName?: string;
  email?: string;
  entitlement?: number;
  phone?: string;
  dob?: string;
  street1?: string;
  street2?: string;
  city?: string;
  state?: string;
  zipCode?: string;
}

export interface IOrganization {
  id: string;
  name: string;
  website: string;
  street1: string;
  street2?: string;
  city: string;
  state: string;
  country: string;
  zipCode: number;
  phone: string;
  vendorStatus?: boolean;
  externalMerchantId?: string;
}

export interface IOrganizationLogo {
  logo?: string;
}

export interface IEmployeePII {
  id: string;
  orgId: number;
  empId: number;
  pan: string;
  panLast4: string;
  created_at: string;
}

export interface INotification {
  id: string;
  module: string;
  orgId: number;
  message: string;
  acknowledge: boolean;
  createdAt: string;
}

export interface IOrganizationExpense {
  id: string;
  category: string;
  amount: number;
  orgId: number;
  expenseDate: string;
  notes: string;
  username: string;
}

export interface ITimesheet {
  id: string;
  hours: number;
  orgId: number;
  timeSheetDate: string;
  username: string;
  startTime?: string;
  endTime?: string;
  notes?: string;
}

export interface IInvoice {
  createdAt: string;
  customer?: IInvoiceCustomer;
  id: string;
  orgId: number;
  items: IProductQuanityRowItem[];
  tax: number;
  total: number;
  grossTotal: number;
  username: string;
  externalId: string;
  notes?: string;
  payments: IPayment[];
  internalNotes?: string;
  includeFooter?: boolean;
  includePaymentLink?: boolean;
}

export type IOrganizationPublic = Pick<
  IOrganization,
  | "city"
  | "country"
  | "name"
  | "phone"
  | "state"
  | "street1"
  | "street2"
  | "zipCode"
>;

export interface IInvoiceReceipt {
  invoice: IInvoice;
  paymentTotal: number;
  organization?: IOrganizationPublic;
  paymentDate: string;
}

export interface IInvoiceEmail {
  id: string;
  customerEmail: string;
  createdAt?: string;
}

export interface IInvoiceCustomerAddress {
  street1: string;
  street2: string;
  city: string;
  state: string;
  countryCode: string;
  zipCode: string;
}

export interface IInvoiceCustomer {
  firstName?: string;
  lastName?: string;
  phone?: string;
  email?: string;
  address?: IInvoiceCustomerAddress;
}

export interface IOrganizationSale {
  amount: number;
  category: string;
  created_at: string;
  id: string;
  orgId: number;
  saleDate: string;
  notes: string;
  username: string;
  payments: IPayment[];
  tax?: number;
  products: IProduct[];
  total: number;
  discountTotal: number;
  pointsTotal?: number;
  externalId: string;
  type?: "RESTAURANT" | "RETAIL" | "FASTFOOD";
}

export type ICreateSaleResponse = IOrganizationSale & {
  paymentStatus: "COMPLETED" | "PENDING";
};

export interface ISaleReturn {
  createdAt: string;
  discountTotal: number;
  grossTotal: number;
  id: string;
  itemsTotal: number;
  notes: string;
  orgId: number;
  products: IProduct[];
  saleId: number;
  username: string;
}

export interface IPayment {
  id: string;
  amount: number;
  created_at: string;
  customerPhone: string;
  paymentType: string;
  paymentDate: string;
  status: "PENDING" | "COMPLETED" | "CANCELLED";
  paypalOrderId?: string;
}

export interface IAggregation {
  month_year: string;
  total: number;
}

export interface IOrganizationInvestment {
  id: string;
  category: string;
  amount: number;
  orgId: number;
  investmentDate: string;
  notes: string;
  username: string;
}

export interface IPayrollSchedule {
  created_at: string;
  created_by: string;
  id: string;
  orgId: number;
  schedule: "weekly" | "biweekly" | "monthly" | "fifteenth";
  startDate: string;
  endDate?: string;
}

export interface IPayrollCycle {
  createdBy: string;
  created_at: string;
  endDate: string;
  id: string;
  orgId: number;
  startDate: string;
}

export interface IEmployeeDetails {
  firstName: string;
  lastName: string;
  city: string;
  country: string;
  email: string;
  phone: string;
  state: string;
  street1: string;
  street2: string;
  zipCode: string;
}

export interface IPayrollEmployee {
  email: string;
  phone: string;
  street1: string;
  street2: string;
  city: string;
  state: string;
  country: string; // full country name
  zipCode: string;
  firstName: string;
  lastName: string; // add SSN or PAN below
  profile?: IProfileDetails;
  panLast4: string;
}

export interface IPayroll {
  amount: number;
  createdBy: string;
  createdAt: string;
  email: string;
  employeeId: number;
  id: string;
  payrollCycleId: number;
  netAmount: number;
  deductions?: IDeduction[];
  bonuses?: IPayrollBonus[];
  employee: IPayrollEmployee;
  organization: IOrganization;
  totalHours?: number;
}

export interface IPayrollStub extends IPayroll {
  cycle: IPayrollCycle;
}

export interface IPayrollPreCheck {
  organization: string[];
  employees: [{ email: string; attributes: string[] }];
  eligible: boolean;
}

export interface IDeduction {
  id: number;
  type: string;
  empId: number;
  name: string;
  orgId: number;
  value: number;
}

export interface IPayrollBonus {
  type: "company" | "employee";
  bonusType: "flat" | "percent";
  id: number;
  amount: number;
  empId: number;
}

export interface IEmployeeSalaryTotal {
  email: string;
  username: string;
  hours?: number;
  salary: number;
}

export interface ITotal {
  total: number;
}

export interface IOrganizationRewardLevel {
  created_at: string;
  created_by: string;
  id: string;
  name: string;
  orgId: string;
  type: "CASH" | "POINTS";
  value: number;
  monetaryType: "FLAT" | "PERCENT";
  minAmount: number;
  externalId?: string;
}

export interface IOrganizationReward {
  created_at: string;
  created_by: string;
  id: number;
  orgId: string;
  phone: string;
  value: number;
}

export interface IReimbursement {
  amount: number;
  category: string;
  created_at: string;
  expense_date: string;
  id: string;
  last_updated: string;
  notes: string;
  org_id: number;
  reimbursement_id: number;
  status: string;
  username: string;
}

export interface IReimbursementAttachment {
  created_at: string;
  id: string;
  imageData: string;
  name: string;
  reimbursementId: string;
  mimetype: "application/pdf" | "image/jpeg";
}

export interface IReimbursementLifecycle {
  createdAt: string;
  createdBy: string;
  id: string;
  notes: string;
  reimbursementId: number;
  status: string;
}

export interface IReferral {
  company_name: string;
  referrals_bio: string;
  referrals_created_date: string;
  referrals_id: string;
  referrals_job_title: string;
  referrals_job_url: string;
  referrals_status: string;
  referrals_candidate: string;
  referrals_last_updated: string;
  referrals_referrer?: string;
  referrals_referral_proof?: string;
}

export interface IReferralLifecycle {
  id: string;
  created_date: string;
  comments: string;
  attachment_id: string;
  referral_id: string;
  status: string;
}

export interface IReferralCandidateDetails {
  firstname: string;
  lastname: string;
}

export interface IReferralAttachment {
  id: string;
  name: string;
  created_date: string;
  referral_id: string;
  url: string;
}

export interface IJobComplaint {
  id: string;
  job_id: string;
  comments: string;
  created_by: string;
  created_date: string;
}

export interface IStatusMsg {
  type: "success" | "error" | "warning" | "info";
  text: any;
}

export interface IListItem {
  title: string;
  subtitle: string;
  icon?: IListComponentIconProps;
}

export interface IListComponentIconProps {
  alt: string;
  path: string;
}

export type IFeedListItem = Omit<IListItem, "icon"> & {
  rightPrimary?: string;
  rightSecondary?: string;
  id: string;
};

export interface IListComponentProps {
  items: IListItem[];
}

export interface IFeedsListComponentProps {
  items: IFeedListItem[];
}

export interface IFeedJobTitle {
  job_job_title: string;
}

export interface ISupportTicket {
  created_date: string;
  description: string;
  id: string;
  status: string;
  title: string;
  username: string;
}

export interface ISupportTicketLifecycle {
  created_date: string;
  last_updated: string;
  description: string;
  title: string;
  status: ISupportTicketLifecycleStatus;
  id: number;
  lifecycle_id: number;
  comments: string;
}

export type ISupportTicketLifecycleStatus =
  | "CREATED"
  | "RESOLVED"
  | "REOPENED"
  | "CLOSED"
  | "UNKNOWN";

// Next status of a lifecycle. Can only be RESOLVED OR REOPENED
export type ISupportTicketLifecycleStatusNext = "RESOLVED" | "REOPENED";

export interface IJobRating {
  job_rating: string;
}

export interface ICompanyReview {
  company_rating: string;
}

export interface IBrowserExtn {
  id: string;
  website_key: string;
  website_page: string;
  job_title_selector: string;
  company_name_selector: string;
}

export interface IPreferenceSkill {
  name: string;
  yrs: number;
}

export interface IUserCredibility {
  id: number;
  username: string;
  type: string;
  job_id?: string;
  job_review_id?: string;
  company_id?: string;
  created_date: string;
  comments?: string;
}

export interface IUserCredibilityStats {
  jpr: number;
  job: number;
  company: number;
}

export interface AttachmentData {
  id: string;
  type?: string;
  createdDate: string;
  url?: string;
  originalFileName: string;
  blobId: string;
}

export interface IMoneyTransfer {
  id: string;
  type: "TRANSFER";
  recipient: string;
  amount: number;
  created_date: string;
  recipient_email: string;
  status: string;
  external_id: string; //paypal order id
  sender_email: string;
  groupid: string;
  disabled?: boolean;
}

export interface IMoneyTransferStatus {
  id: string;

  created_date: string;
  money_transfer_id: string;
  status: string;
}

export interface IMoneyTransferWebHook {
  id: string;

  data: string;
  data_json: any;
  created_date: string;
  order_id: string;
  acknowledged: boolean;
}

export interface IMoneyTransferPayout {
  amount: number;
  created_date: string;
  currency: string;
  id: string;
  money_transfer_id: string;
  payout_batch_id: string;
  payout_item_id: string;
  recipient_email: string;
  recipient_first_name: string;
  recipient_last_name: string;
  recipient_phone: string;
}

export interface IMoneyTransferPayoutItemDetails {
  payout_item_id: string;
  transaction_status: string; // "FAILED"
  payout_batch_id: string;
  sender_batch_id: string;
  errors?: IError;
}

export interface IError {
  name: string; // https://developer.paypal.com/docs/payouts/standard/reports/view-transaction-activities/
  message: string;
  details: any[];
}

export interface IProduct {
  _id: string;
  price: IAmount;
  createdAt: string;
  title: string;
  description?: string;
  externalId?: string;
  quantity?: number;
  sku?: string;
  salesTaxExcluded?: boolean;
  coverId?: string;
  cover?: string;
  restock?: boolean;
  vendorId?: string;
  restockQuantity?: number;
  restockThreshold?: number;
}

export type IVendorSearchResult = Pick<
  IOrganization,
  "id" | "name" | "city" | "country" | "phone"
>;

export interface IVendor {
  id: string;
  name: string;
  orgId: number;
  phone: string;
  vendorOrgId: number;
  email: string;
}

export type IVendorDetails = IVendor & { active: boolean };

export interface IAmount {
  currency?: string;
  value: number;
  oldValue?: number;
}

export interface IProductImage {
  image: string;
  productId: string;
  id: string;
}

export interface IProfileSettings {
  orders: boolean;
  reimbursements: boolean;
}

export interface IRestaurantTable {
  closed_at: string;
  created_at: string;
  id: string;
  notes?: string;
  orgId: number;
  username: string;
  value: string;
  products?: IProduct[];
  tax?: number;
  tip?: number;
}

export interface IProductQuanityRowItem {
  quantity: number;
  product: IProduct | null;
}

export interface IStockRequestItem {
  product: Pick<IProduct, "title">;
  quantity: number;
}

export enum STOCKREQUEST_STATUS {
  SAVED = "SAVED",
  SUBMITTED = "SUBMITTED",
  CANCELLED = "CANCELLED",
  PROCESSING = "PROCESSING",
  ON_ROUTE = "ON_ROUTE",
  DELAYED = "DELAYED",
  COMPLETED = "COMPLETED",
  BLOCKED = "BLOCKED",
}

export enum PAYMENT_TYPE {
  CASH = "CASH",
  CARD = "CARD",
  PAYPAL = "PAYPAL",
  CHECK = "CHECK",
  OTHER = "OTHER",
  ACH = "ACH",
  APPLE_PAY = "APPLE_PAY",
  GOOGLE_PAY = "GOOGLE_PAY",
}

export enum CURRENCY {
  USD = "USD",
  CAD = "CAD",
  INR = "INR",
}

export interface IKitchenItem {
  createdAt: string;
  createdBy: string;
  id: string;
  orgId: number;
  productId: string;
  productName: string;
  status: "PROGRESS" | "READY";
  tableId?: number;
  updatedAt?: string;
  updatedBy?: string;
  assignee?: string;
}
