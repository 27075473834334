import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useGetOrganizationById } from "../api/api.get";
import { PAYMENT_TYPE } from "../types/types";

export function usePaymentOptions() {
  const { id } = useParams();
  const { data } = useGetOrganizationById(id);
  const [paymentOptions, setPaymentOptions] = useState<IPaymentOption[]>();

  useEffect(() => {
    if (data) {
      if (data.country === "USA") {
        setPaymentOptions([
          { value: PAYMENT_TYPE.CASH, label: "Cash" },
          {
            value: PAYMENT_TYPE.CARD,
            label: "Credit Card",
            disabled: !!data.externalMerchantId ? false : true,
          },
          {
            value: PAYMENT_TYPE.APPLE_PAY,
            label: "Apple Pay",
            disabled: false,
          },
          {
            value: PAYMENT_TYPE.GOOGLE_PAY,
            label: "Google Pay",
            disabled: true,
          },
        ]);
      } else if (data.country === "IND") {
        setPaymentOptions([
          { value: PAYMENT_TYPE.CASH, label: "Cash" },
          { value: PAYMENT_TYPE.OTHER, label: "Other" },
        ]);
      } else {
        setPaymentOptions([{ value: "", label: "" }]);
      }
    }
  }, [data]);

  return { paymentOptions };
}

interface IPaymentOption {
  label: string;
  value: string;
  disabled?: boolean;
}
