import { IProduct, IProductQuanityRowItem } from "../types/types";
import { toFixed } from "./number.utils";

export function findProductsWithZeroOrNoQuantity(products: IProduct[]) {
  return products.find(
    (product: IProduct) => !product.quantity || product.quantity <= 0,
  );
}

/**
 * Returns product that is present in cart more than available quantity.
 * For ex: if Amul butter has quantity 1, then this will return it if Amul butter is added two items in cart.
 * @param products
 * @returns
 */
export function findProductsWithInsufficientQuantitities(
  products: IProduct[],
): IProduct | undefined {
  const productCountMap = calculateProductCountTotal(products);

  for (const p of products) {
    const countInCart = productCountMap.get(p._id);
    if (countInCart && countInCart > (p.quantity ?? 0)) {
      return p;
    }
  }
}

export function validateItems(items: IProductQuanityRowItem[]) {
  for (const item of items) {
    if (!item.product || item.quantity <= 0) {
      return false;
    }
  }
  return true;
}

// returns complete total along with tax by computing each product if it is sales tax included or not
export function calculateProductQuantityTotal(items: IProductQuanityRowItem[]) {
  const total = items.reduce(
    (acc, item) => acc + item.quantity * (item.product?.price.value ?? 0),
    0,
  );
  return toFixed(total);
}

// returns complete total along with tax by computing each product if it is sales tax included or not
export function calculateProductQuantityTotalWithTax(
  items: IProductQuanityRowItem[],
  tax: number,
) {
  let total = 0;

  if (items) {
    for (const item of items) {
      const itemPrice = item.product?.price?.value ?? 0;

      if (item.product?.salesTaxExcluded === true) {
        total = total + itemPrice * item.quantity;
      } else {
        total =
          total +
          itemPrice * item.quantity +
          (tax / 100) * itemPrice * item.quantity;
      }
    }
  }

  return toFixed(total);
}

/**
 * Calculates all products total including if sales tax included
 * @param items
 * @param tax - [optional] since org sales tax might not be set sometimes
 * @returns value upto 2 decimals
 */
export function calculateProductTotal(
  items: IProduct[],
  tax?: number | string,
  tip?: number | string,
  type?: string,
  storeCredits?: number | null,
) {
  let total = 0;
  const salesTax = tax && !isNaN(+tax) ? +tax : 0;
  const tipAmount = tip && !isNaN(+tip) ? +tip : 0;
  const storeCreditsAmount = storeCredits ?? 0;

  for (const item of items) {
    const itemPrice = item.price?.value ?? 0;
    if (item.salesTaxExcluded === true) {
      total = total + itemPrice;
    } else {
      total = total + itemPrice + (salesTax / 100) * itemPrice;
    }
  }

  if (storeCreditsAmount) {
    total = total - storeCreditsAmount;
    if (total < 0) {
      total = 0;
    }
  }

  if (type === "PAYPAL") {
    total = total + (total * 4) / 100;
  }
  return toFixed(total + tipAmount);
}

export function calculateProductCountTotal(
  items: IProduct[],
  tax?: number,
): Map<string, number> {
  const countMap = new Map<string, number>();

  for (const item of items) {
    const count = countMap.get(item._id);

    if (count) {
      countMap.set(item._id, count + 1);
    } else {
      countMap.set(item._id, 1);
    }
  }
  return countMap;
}
