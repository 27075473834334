import Lottie from "react-lottie";
import * as paymentPendingAnimation from "../animations/shopping-cart-payment.json";

export function PaymentPendingLottieLoading() {
  return (
    <LottieViewerComponent
      animationData={paymentPendingAnimation}
      size={200}
      title="Waiting for payment..."
    />
  );
}

function LottieViewerComponent({
  animationData,
  size = 400,
  title,
}: {
  animationData: any;
  size?: number;
  title?: string;
}) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="divCenterAlign divColumn">
      <h3>{title}</h3>
      <Lottie options={defaultOptions} height={size} width={size} />
    </div>
  );
}
