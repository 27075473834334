export default function SuccessPill({
  title,
  type,
}: {
  title?: string;
  type?: "success" | "error" | "warning";
}) {
  return (
    <div
      style={{
        backgroundColor:
          type === "success"
            ? "limegreen"
            : type === "error"
              ? "red"
              : "orange",
        padding: 10,
        alignItems: "center",
        textAlign: "center",
        width: "fit-content",
        borderRadius: 20,
      }}
    >
      <strong
        style={{ fontSize: 15, color: "white" }}
        className="divCenterAlign"
      >
        {title}
      </strong>
    </div>
  );
}

export function SuccessPillWithColor({
  title,
  color,
  fontColor,
  borderColor,
  size,
}: {
  size?: "sm" | "med";
  title?: string;
  color?: string;
  fontColor?: string;
  borderColor?: string;
}) {
  return (
    <div
      style={{
        backgroundColor: color,
        padding: size === "sm" ? 2 : 10,
        alignItems: "center",
        textAlign: "center",
        width: 100,
        borderRadius: 20,

        border: `1px solid ${borderColor}`,
      }}
    >
      <span style={{ color: fontColor ?? "white" }} className="divCenterAlign">
        {title}
      </span>
    </div>
  );
}
