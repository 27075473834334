import { Link } from "react-router-dom";
import { useLayoutContext } from "../providers/layout.provider";
import { useLocalizationContext } from "../providers/localization.provider";
import "../styles/landing-page.css";

export default function LandingPage() {
  const { isMobile } = useLayoutContext();
  const { strings } = useLocalizationContext();

  return (
    <div className="body" id="landing-page">
      <div className={isMobile ? "mx-60 my-40" : "mx-80 my-40"}>
        <div className={`divRight mb-40`}>
          <Link to={"/register"} className="blueButton">
            Sign up
          </Link>
        </div>

        <TextToImage
          isMobile={isMobile}
          title={strings.landingPageTitle1}
          subtitleElem={
            <div>
              <p>
                ExpenseHut is the all-in-one business platform designed to
                simplify sales, customer management, and rewards. With an
                advanced <a href="#landing-pos">POS</a> system, AI-driven{" "}
                <a href="#landing-product-recomm">product recommendations </a> ,
                and built-in CRM, we help businesses grow smarter.
              </p>

              <div
                className={`my-40 ${isMobile ? "divColumn divCenterAlign" : ""}`}
              >
                <div className="my-10">
                  <strong>Download</strong>
                </div>
                <a
                  href="https://exhutstorageaccount.blob.core.windows.net/public/app-release.apk"
                  className="download-btn"
                >
                  <img
                    src={"/assets/icons/playstore.svg"}
                    alt="Download Icon"
                    style={{ width: 20, marginRight: 10 }}
                  />
                  POS Android
                </a>
              </div>
            </div>
          }
          even={true}
          imgName={"assets/landingpage/business.png"}
        />

        <hr className="my-80" />

        <ImageToText
          id="landing-pos"
          isMobile={isMobile}
          title={"Next-Gen Point-of-Sale"}
          subtitleElem={
            <div className="mt-20">
              <ul style={{ listStyle: "disc", lineHeight: 1.5 }}>
                <li>Best user experience for merchants.</li>
                <li>Supports omni-channel of payment modes</li>
                <li>
                  Credit Card, <a href="#landing-click-to-pay">Click-to-pay</a>{" "}
                  <sup>1</sup> & Offline <sup>2</sup>
                </li>
                <li>No hardware required. Run from anywhere.</li>
              </ul>
            </div>
          }
          roundedRadius={10}
          imgWidth={400}
          imgName={"assets/landingpage/pos.png"}
        />

        <hr className="my-80" />

        <TextToImage
          isMobile={isMobile}
          title={"Invoices"}
          subtitle={
            "Send invoices with no limits and accept payments. Follow up via SMS or email for free."
          }
          subtitleSupIndex={"3"}
          even={true}
          imgName={"assets/landingpage/invoices.png"}
        />
        <hr />

        <ImageToText
          imgWidth={400}
          id="landing-product-recomm"
          isMobile={isMobile}
          title={"AI powered product catalogue"}
          subtitle={
            "Merchants receive product recommendations to expand their inventory and boost sales. Our system ensures they stock the right products at the right time, boosting profitability and growth."
          }
          even={true}
          subtitleSupIndex={"4"}
          imgName={"assets/landingpage/ai-catalogue.png"}
        />
        <div className="my-80">
          <hr />

          <CenterItem
            bgcolor={"var(--exhut-light-grey5)"}
            isMobile={isMobile}
            title={strings.landingAllBusinessAllSize}
            subtitleElem={
              <div className="mt-20">
                <p>Designed for all types of businesses.</p>
                <p>
                  Our POS supports 3 types - restaurants, fastfood and retail.
                </p>
                <p>All 3 included in all subscriptions.</p>
              </div>
            }
            even={true}
          />
          <hr />
        </div>

        <TextToImage
          id="landing-click-to-pay"
          isMobile={isMobile}
          title={"Tap to pay"}
          subtitle={
            "Allow customers to pay using ExpenseHut app via Apple Pay or Google Pay. Pre-orders inclusive."
          }
          imgWidth={200}
          subtitleSupIndex={"5"}
          even={true}
          roundedRadius={30}
          imgName={"assets/landingpage/customer-pay.png"}
        />
        <hr className="my-80" />

        <ImageToText
          isMobile={isMobile}
          title={"Coupons & Rewards"}
          subtitle={
            "Fully customizable rewards platform. Merchants can control rewards and manage redemption in few simple steps."
          }
          even={true}
          imgName={"assets/landingpage/rewards.jpg"}
          imgWidth={400}
        />
        <hr className="my-80" />

        <TextToImage
          isMobile={isMobile}
          title={"Digital Receipts"}
          subtitle={
            "Your customers will no longer lose receipts. We store them upto 5 years."
          }
          imgWidth={200}
          even={true}
          imgName={"assets/landingpage/digital-receipt.png"}
          roundedRadius={30}
        />
        <hr className="my-80" />

        <ImageToText
          isMobile={isMobile}
          title={"Your customers. Your CRM."}
          subtitle={
            "Build a dedicated notification channels to your customers. Annouce your discounts, offers and more without needing to pay for ads."
          }
          even={true}
          imgWidth={200}
          imgName={"assets/icons/announcements.svg"}
        />
        <hr className="my-80" />

        <TextToImage
          isMobile={isMobile}
          title={"Inventory Management"}
          subtitle={"Automatically set up stock requests with your vendors."}
          imgWidth={isMobile ? 300 : 450}
          subtitleSupIndex={""}
          even={true}
          imgName={"assets/landingpage/inventory.jpg"}
        />
        <hr className="my-80" />

        <ImageToText
          isMobile={isMobile}
          title={"Manage Employees"}
          subtitle={"Track employees hours for payroll."}
          even={true}
          imgName={"assets/landingpage/workers.png"}
        />
        <hr className="my-80" />

        <TextToImage
          title={"Track every dollar you spend"}
          subtitle={
            "Sit back relax while we do the math for expenses & profits"
          }
          imgName={"assets/landingpage/expenses-1.jpg"}
          isMobile={isMobile}
          even={true}
          imgWidth={400}
        />
        <hr className="my-80" />

        <ImageToText
          isMobile={isMobile}
          title={"Barcode Scanner Support"}
          subtitle={"POS supports all types of scanners."}
          even={true}
          imgName={"assets/landingpage/qrcode.png"}
          imgWidth={200}
        />
        <hr />

        <TextToImage
          isMobile={isMobile}
          title={"Free Monthly reports"}
          subtitle={
            "Get scheduled reports from your business each month for free."
          }
          even={true}
          imgName={"assets/landingpage/weekly-reports.svg"}
          imgWidth={300}
        />
        <hr className="my-80" />

        <CenterItem
          isMobile={isMobile}
          title={"No Credit Card Required"}
          subtitleElem={
            <div>
              <p>Use full servicing platform for free.</p>
              <p>Pay only for what you use.</p>
              <p>Not impressed? Cancel anytime.</p>
            </div>
          }
          even={true}
        />
        <hr className="my-80" />

        <FinalItem />

        <hr className="my-80" />

        <div className="my-40">
          <ul className="infoMsg mediumText">
            <li>
              1. Currently in Beta version and requires premium subscription.
              Only available in US.
            </li>
            <li>2. In Beta. Available in US only.</li>
            <li>
              3. SMS delivery requires premium subscription. Email is free.
            </li>
            <li>4 & 5 . Available for US customers only.</li>
          </ul>
        </div>
      </div>
    </div>
  );
}

interface IProps {
  bgcolor?: string;
  id?: string;
  isMobile?: boolean;
  title?: string;
  titleElem?: any;
  subtitleElem?: any;
  subtitle?: string;
  subtitleSupIndex?: string;
  imgName?: string;
  imgWidth?: number;
  even?: any;
  ternary?: any;
  containerStyle?: any;
  roundedRadius?: number;
}

function TextToImage({
  isMobile,
  title,
  titleElem,
  subtitle,
  subtitleElem,
  subtitleSupIndex,
  imgName,
  imgWidth = 300,
  even,
  ternary,
  roundedRadius,
  containerStyle,
  bgcolor,
  id,
}: IProps) {
  return (
    <div
      className={
        isMobile
          ? "divColumn divCenterAlign my-60"
          : `${even ? "divSpreadEven" : "divSpread"} my-60`
      }
      id={id}
      style={{ ...containerStyle, backgroundColor: bgcolor }}
    >
      <div className={isMobile ? "mb-40" : "mb-20 w-50"}>
        {title && (
          <strong style={{ fontSize: 35, fontWeight: "" }}>{title}</strong>
        )}
        {titleElem}

        <div style={{ paddingRight: 0, marginTop: 25 }}>
          {subtitleElem}
          {!subtitleElem && (
            <p style={{ color: "darkslategrey", lineHeight: 1.5 }}>
              {subtitle}
              {subtitleSupIndex && (
                <sup style={{ color: "darkslategray" }}>{subtitleSupIndex}</sup>
              )}
            </p>
          )}
        </div>
        {ternary}
      </div>

      {imgName && (
        <img
          src={imgName}
          style={{ borderRadius: roundedRadius }}
          width={imgWidth}
          alt={imgName}
        />
      )}
    </div>
  );
}

function ImageToText({
  title,
  subtitle,
  subtitleSupIndex,
  imgName,
  isMobile,
  ternary,
  imgWidth = 300,
  bgcolor,
  roundedRadius,
  containerStyle,
  subtitleElem,
  even,
  id,
}: IProps) {
  if (isMobile) {
    return (
      <TextToImage
        id={id}
        title={title}
        subtitle={subtitle}
        imgName={imgName}
        isMobile={isMobile}
        imgWidth={imgWidth}
        containerStyle={containerStyle}
        bgcolor={bgcolor}
        subtitleElem={subtitleElem}
        roundedRadius={roundedRadius}
      />
    );
  }

  return (
    <div
      id={id}
      className={`divSpreadEven p-40`}
      style={{ ...containerStyle, backgroundColor: bgcolor }}
    >
      {imgName && (
        <img
          src={imgName}
          width={imgWidth}
          alt={"alt-img"}
          style={{ borderRadius: roundedRadius }}
        />
      )}

      <div className={"divColumn p-40 mb-20 w-40"}>
        <strong style={{ fontSize: 30, fontWeight: "" }}>{title}</strong>
        <div style={{ paddingRight: 32 }}>
          {subtitleElem}
          <p style={{ color: "darkslategrey" }}>
            {subtitle}
            {subtitleSupIndex && (
              <sup style={{ color: "darkslategray" }}>{subtitleSupIndex}</sup>
            )}
          </p>
        </div>
        {ternary}
      </div>
    </div>
  );
}

function CenterItem({
  title,
  subtitle,
  imgName,
  ternary,
  isMobile,
  imgWidth = 300,
  bgcolor,
  subtitleElem,
}: IProps) {
  return (
    <div
      className={"divColumn divCenterAlign p-40"}
      style={{ backgroundColor: bgcolor }}
    >
      <div className={isMobile ? "mb-40" : "mb-20 w-40"}>
        <span style={{ fontSize: 30, fontWeight: "bold" }}>{title}</span>
        <div style={{ paddingRight: 32 }}>
          <p style={{ lineHeight: 1.3 }}>{subtitle}</p>
          {subtitleElem}
        </div>
        {ternary}
      </div>

      {imgName && (
        <div className={isMobile ? "mb-40" : "mb-20 w-50"}>
          <img
            src={`/assets/landingpage/${imgName}`}
            width={imgWidth}
            alt={imgName}
          />
        </div>
      )}
    </div>
  );
}

function FinalItem({ isMobile }: IProps) {
  return (
    <div
      className={"divColumn divCenterAlign my-40 p-40"}
      style={{ backgroundColor: "#FFDEFE", borderRadius: 30 }}
    >
      <div className={isMobile ? "mb-10" : "mb-10 w-100"}>
        <span style={{ fontSize: 30, fontWeight: "bold" }}>
          Lets start business!
        </span>
        <div style={{ paddingRight: 32 }}>
          <p style={{ lineHeight: 1.3 }}>Powered by Expensehut</p>
        </div>

        <div className="divCenterAlign">
          <Link to={"/register"} className="blueButton">
            Sign up
          </Link>
        </div>
      </div>
    </div>
  );
}

export function AndroidAppLink() {
  return (
    <div>
      <a
        href="https://play.google.com/store/apps/details?id=com.snarain.expensehut"
        rel="noreferrer"
        target="_blank"
      >
        <img
          src="assets/google-play-badge.png"
          width="150px"
          alt="android play store"
        />
      </a>
    </div>
  );
}

export function IOSAppLink() {
  return (
    <div>
      <a
        href="https://apps.apple.com/us/app/expense-hut/id1562719499"
        rel="noreferrer"
        target="_blank"
      >
        <img src="assets/apple-store-badge.svg" width="130px" alt="ios" />
      </a>
    </div>
  );
}
