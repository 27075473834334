import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { EXHUT_BIZ_SALES_TERMINAL_ID } from "../utils/constants";

export function useSalesTerminalsHook() {
  const { pathname } = useLocation();
  const [salesTerminalId, setSalesTerminalId] = useState<string | null>();

  useEffect(() => {
    if (pathname) {
      setSalesTerminalId(localStorage.getItem(EXHUT_BIZ_SALES_TERMINAL_ID));
    }
  }, [pathname]);

  return {
    terminalId: salesTerminalId,
  };
}
