import { getCountryPhoneCode } from "./constants";

export function capitalizeFromSnakeCase(value: string = "") {
  return value
    ?.toLowerCase() // Convert the entire string to lowercase
    .replace(/_/g, " ") // Replace underscores with spaces
    .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize each word
}

export function capitalize(input: string = "") {
  if (!input) return "";
  const words = input.split(" ");
  const result = [];
  for (const w of words) {
    result.push(`${w.charAt(0).toUpperCase()}${w.substring(1)}`);
  }
  return result.join(" ");
}

export function truncate(input: string = "", limit: number = 15) {
  if (input.length > limit) {
    return input.substring(0, limit) + "...";
  }
  return input;
}

export function last4(input: string = "", limit: number = 4) {
  if (input.length > limit) {
    return input.substring(input.length - limit);
  }
  return input;
}

export function capitalizeWithTrunc(input: string = "", limit: number = 15) {
  const capitalized = capitalize(input);
  if (capitalized.length > limit) {
    return capitalized.substring(0, limit) + "...";
  }
  return capitalized;
}

export function getCurrencySymbol(countryCode: string = "USA") {
  if (!countryCode) {
    return "$"; // always return $ in any cases
  }
  let symbol = "$";
  switch (countryCode) {
    case "IND":
      symbol = "₹";
      break;
    case "UK":
      symbol = "£";
      break;
    case "JPN":
      symbol = "¥";
      break;
  }
  return symbol;
}

/**
 * Products have current as USD, INR. This will return $ and ₹.
 *
 * Use this wherever you are getting product prices.
 * @param countryCode
 * @returns
 */
export function getProductCurrencySymbol(currencyCode?: string) {
  if (!currencyCode) {
    return "$"; // always return $ in any cases
  }
  let symbol = "$";
  switch (currencyCode) {
    case "INR":
      symbol = "₹";
      break;
    case "GBP":
      symbol = "£";
      break;
    case "JPY":
      symbol = "¥";
      break;
  }
  return symbol;
}
/**
 * Returns USD for country code USA
 * @param countryCode
 * @returns
 */
export function getCurrencyCode(countryCode: string = "USA") {
  if (!countryCode) {
    return "USD"; // always return $ in any cases
  }
  let symbol = "USD";
  switch (countryCode) {
    case "IND":
      symbol = "INR";
      break;
    case "UK":
      symbol = "GBP";
      break;
    case "JPN":
      symbol = "JPY";
      break;
  }
  return symbol;
}

export function matchPublicRoutes(pathname: string) {
  const PUBLIC_ROUTES = [
    "/",
    "/forgot-password",
    "/register",
    "/register/verify",
    "/login",
    "/receipts",
  ];
  return PUBLIC_ROUTES.includes(pathname);
}

export function displayPhoneNumber(
  phone?: string,
  countryCode?: string | null,
) {
  if (phone && countryCode) {
    const countryCodeToTrim = getCountryPhoneCode(countryCode);
    return phone.replace(countryCodeToTrim, "");
  }
  return "";
}

export function getRecaptchaKey() {
  return process.env.REACT_APP_RECAPTCHA_KEY ?? "";
}

export const isRecaptchaEnabled =
  process.env.REACT_APP_RECAPTCHA_ENABLED === "true";
