import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";
import { Button, Flex, Image, Modal, Select, Spin, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  useGetSalesTerminalById,
  useGetSalesTerminals,
} from "../../../api/api.get";
import { useMessage } from "../../../components/notifications/message";
import { useProfile } from "../../../hooks/useProfile";
import { useSalesTerminalsHook } from "../../../hooks/useSalesTerminalsHook";
import { EXHUT_BIZ_SALES_TERMINAL_ID } from "../../../utils/constants";
import Alert from "antd/es/alert/Alert";

export default function SalesTerminals() {
  const { id } = useParams();
  const { profile } = useProfile();
  const [showTerminalsModal, setShowTerminalsModal] = useState<boolean>(false);
  const { data: terminalsResponse, isLoading } = useGetSalesTerminals(id);
  const [options, setOptions] = useState<{ label: string; value: string }[]>(
    [],
  );
  const [selectedTerminal, setSelectedTerminal] = useState<string>();
  const { notification, messageError } = useMessage();
  const { terminalId } = useSalesTerminalsHook();
  const [terminalIcon, setTerminalIcon] = useState<string>();
  const {
    data: terminalStatusData,
    refetch,
    error: deviceStatusError,
    isLoading: isDeviceStatusLoading,
  } = useGetSalesTerminalById(id, selectedTerminal);

  useEffect(() => {
    if (terminalStatusData?.data.connectionStatus === "connected") {
      setTerminalIcon("/assets/icons/pos-connected.png");
    } else {
      setTerminalIcon("/assets/icons/pos-unknown.png");
    }
  }, [terminalStatusData]);

  useEffect(() => {
    if (terminalsResponse?.data) {
      setOptions(
        terminalsResponse.data.devices.map((device) => {
          return {
            label: device.deviceNickname,
            value: device.deviceId,
          };
        }),
      );
    }
  }, [terminalsResponse]);

  const handleSaveTerminal = async () => {
    try {
      await refetch();
      localStorage.setItem(EXHUT_BIZ_SALES_TERMINAL_ID, selectedTerminal!!);
      setShowTerminalsModal(false);
    } catch (err: any) {
      messageError("Unable to connect to terminal. Error: " + err.message);
    }
  };

  const handleCheckTerminalStatus = () => {
    refetch();
  };

  return (
    <div>
      <Modal
        title="Terminals"
        open={showTerminalsModal}
        onOk={() => handleSaveTerminal()}
        onCancel={() => setShowTerminalsModal(false)}
        width={800}
        okButtonProps={{
          disabled: terminalStatusData?.data?.connectionStatus !== "connected",
        }}
      >
        {notification}
        <Flex gap={10} vertical style={{ width: "100%" }}>
          <Flex gap={20} vertical style={{ padding: 20, flex: 1 }}>
            <Flex gap={20} className="divAlignItemsOnly">
              <Select
                loading={isLoading}
                value={selectedTerminal}
                placeholder={"Select terminal"}
                onChange={(value) => setSelectedTerminal(value)}
                style={{ width: 200 }}
                options={options}
              />

              {!!selectedTerminal && (
                <Button
                  onClick={handleCheckTerminalStatus}
                  loading={isDeviceStatusLoading}
                >
                  Check Status
                </Button>
              )}
              {!!terminalStatusData && (
                <Flex>
                  {terminalStatusData?.data?.connectionStatus ===
                    "connected" && (
                    <CheckCircleFilled
                      style={{ color: "limegreen", fontSize: 20 }}
                    />
                  )}
                  {terminalStatusData?.data?.connectionStatus !==
                    "connected" && (
                    <CloseCircleFilled
                      style={{ color: "coral", fontSize: 20 }}
                    />
                  )}
                </Flex>
              )}
              {!!deviceStatusError && (
                <CloseCircleFilled style={{ color: "coral", fontSize: 20 }} />
              )}
              {isDeviceStatusLoading && <Spin />}
            </Flex>
          </Flex>

          {deviceStatusError && (
            <div className="divCenterAlign">
              <Alert type="error" message="Cannot connect to device" />
            </div>
          )}
        </Flex>
      </Modal>

      <Flex className="divAlignItemsOnly">
        <Tooltip
          title={!!terminalId ? "Terminal Connected" : "Terminal Disconnected"}
        >
          <Button
            disabled={profile?.countryCode !== "USA"}
            type="text"
            id="terminals-btn"
            icon={<Image src={terminalIcon} preview={false} />}
            onClick={() => setShowTerminalsModal(true)}
          ></Button>
        </Tooltip>
      </Flex>
    </div>
  );
}
