import { CompressOutlined } from "@ant-design/icons";
import { Flex } from "antd";
import { Link, Route, Routes, useParams } from "react-router-dom";
import Notifications from "./components/notifications.component";
import {
  RequireEmployeeAccess,
  RequireOrgAdminAccess,
} from "./higher-order/require-access";
import {
  RequireReimbursementsSettingsOrAdmin,
  RequireSalesSettingsOrAdmin,
} from "./higher-order/require-settings";
import HomePage from "./pages/home.page";
import Profile from "./pages/home/profile.page";
import NoAccessPage from "./pages/no-access.page";
import OrganizationEmployeesHomePage from "./pages/organizations/employees/employees-home";
import ViewEmployeePage from "./pages/organizations/employees/view-employee.page";
import OrganizationExpensesHomePage from "./pages/organizations/expenses/expenses-home";
import OrganizationInvestmentsHomePage from "./pages/organizations/investments/investments-home";
import AddInvoice2 from "./pages/organizations/invoices/add-invoice";
import EditInvoice from "./pages/organizations/invoices/edit-invoice";
import InvoicesHomePage from "./pages/organizations/invoices/invoices.home";
import { OrgHomeTopPaneComponent } from "./pages/organizations/leftpane.component";
import NotificationsHomePage from "./pages/organizations/notifications/notifications.home";
import OrganizationHomePage from "./pages/organizations/organization-home";
import PayrollCycleHomePage from "./pages/organizations/payroll/payroll-cycle-home";
import PayrollHomePage from "./pages/organizations/payroll/payroll-home";
import PayrollHomEmployeePage from "./pages/organizations/payroll/payroll-home-employee";
import ProductsEditPage from "./pages/organizations/products/edit-products";
import ProductsHomePage from "./pages/organizations/products/products-home";
import ReimbursementsHomePage from "./pages/organizations/reimbursements/reimbursements-home";
import RewardsHomePage from "./pages/organizations/rewards/reward-home";
import FastFoodPage from "./pages/organizations/sales/fastfood/fastfood";
import RestaurantsHomePage from "./pages/organizations/sales/restaurants/restaurants.home";
import RestaurantTabPage from "./pages/organizations/sales/restaurants/restaurants.tab";
import CheckoutPage from "./pages/organizations/sales/retail/checkout";
import ReturnsPage from "./pages/organizations/sales/retail/returns";
import OrganizationSalesHomePage from "./pages/organizations/sales/sales-home";
import OrganizationSettings from "./pages/organizations/settings";
import InvoiceSettingsPage from "./pages/organizations/settings/invoice-settings.page";
import OrganizationTaxesSettingsPage from "./pages/organizations/settings/taxes-settings.page";
import VendorSettingsPage from "./pages/organizations/settings/vendor-settings.page";
import AddStockRequestPage from "./pages/organizations/supplychain/stock-requests/add-stock-request.page";
import StockRequestsPage from "./pages/organizations/supplychain/stock-requests/stock-requests.page";
import ViewStockRequestPage from "./pages/organizations/supplychain/stock-requests/view-stock-request.page";
import TimeSheetsHomePage from "./pages/organizations/timesheets/timesheet-home";
import VendorsPage from "./pages/organizations/vendors/vendors.page";
import ViewVendorsPage from "./pages/organizations/vendors/view-vendor.page";
import { useFullViewContext } from "./providers/fullview.provider";
import OrganizationCRMHomePage from "./pages/organizations/crm/crm-home";
import ViewCRMPage from "./pages/organizations/crm/view-crm-page";
import KitchenHomePage from "./pages/organizations/kitchen/kitchen";
import KitchenComponent from "./components/kitchen.component";

export const AuthenticatedRoutes = () => {
  return (
    <div>
      <Routes>
        <Route path="/home" element={<HomePage />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/organizations/:id/*" element={<OrganizationRoutes />} />
        <Route path="*" element={<NoAccessPage />} />
      </Routes>
    </div>
  );
};

const OrganizationRoutes = () => {
  const { id } = useParams();
  const employeeRoutes = (
    <RequireEmployeeAccess>
      <Routes>
        <Route path="/" element={<TimeSheetsHomePage />} />
      </Routes>
    </RequireEmployeeAccess>
  );
  const orgAdminRoutes = (
    <RequireOrgAdminAccess>
      <Routes>
        <Route path="/notifications" element={<NotificationsHomePage />} />
        <Route path="/payroll-cycles/:pid" element={<PayrollCycleHomePage />} />

        <Route path="/payroll-cycles" element={<PayrollHomePage />} />
        <Route path="/products/:pid" element={<ProductsEditPage />} />
        <Route path="/products" element={<ProductsHomePage />} />

        <Route path="/stocks/:sid" element={<ViewStockRequestPage />} />
        <Route path="/stocks/add" element={<AddStockRequestPage />} />
        <Route path="/stocks" element={<StockRequestsPage />} />

        <Route path="/vendors/:vid" element={<ViewVendorsPage />} />
        <Route path="/vendors" element={<VendorsPage />} />

        <Route path="/invoices/create" element={<AddInvoice2 />} />
        <Route path="/invoices/:iid" element={<EditInvoice />} />
        <Route path="/invoices" element={<InvoicesHomePage />} />

        <Route path="/rewards" element={<RewardsHomePage />} />
        <Route
          path="/settings/taxes"
          element={<OrganizationTaxesSettingsPage />}
        />
        <Route path="/settings/vendors" element={<VendorSettingsPage />} />
        <Route path="/settings/invoices" element={<InvoiceSettingsPage />} />
        <Route path="/settings" element={<OrganizationSettings />} />

        <Route
          path="/investments"
          element={<OrganizationInvestmentsHomePage />}
        />
        <Route path="/employees/:eid" element={<ViewEmployeePage />} />
        <Route path="/employees" element={<OrganizationEmployeesHomePage />} />
        <Route path="/expenses" element={<OrganizationExpensesHomePage />} />
        <Route path="/crm/:cid" element={<ViewCRMPage />} />
        <Route path="/crm" element={<OrganizationCRMHomePage />} />
      </Routes>
    </RequireOrgAdminAccess>
  );

  const requireSalesSettingsOrAdmin = (
    <RequireSalesSettingsOrAdmin>
      <Routes>
        <Route path="/:sid/returns" element={<ReturnsPage />} />
        <Route path="/retail" element={<CheckoutPage />} />
        <Route path="/restaurants/ff" element={<FastFoodPage />} />
        <Route path="/restaurants/tabs/:tid" element={<RestaurantTabPage />} />
        <Route path="/restaurants" element={<RestaurantsHomePage />} />
        <Route path="/" element={<OrganizationSalesHomePage />} />
      </Routes>
    </RequireSalesSettingsOrAdmin>
  );

  const requireReimbursementsSettingsOrAdmin = (
    <RequireReimbursementsSettingsOrAdmin>
      <Routes>
        <Route path="/" element={<ReimbursementsHomePage />} />
      </Routes>
    </RequireReimbursementsSettingsOrAdmin>
  );

  const { setFullView, fullView } = useFullViewContext();

  return (
    <div>
      <Flex className="divSpread pr-20">
        <Flex gap={20} className="divAlignItemsOnly pr-20">
          <OrgHomeTopPaneComponent />
          <Link to={`/organizations/${id}`} id="org-home">
            <img src={"/assets/icons/house.svg"} width={20} alt="home" />
          </Link>

          <CompressOutlined onClick={() => setFullView(!fullView)} />
        </Flex>

        <Flex className="divAlignItemsOnly pr-20" gap={10}>
          <KitchenComponent />

          <Notifications />
        </Flex>
      </Flex>

      <Routes>
        <Route
          path="/reimbursements"
          element={requireReimbursementsSettingsOrAdmin}
        />
        <Route path="/sales/*" element={requireSalesSettingsOrAdmin} />
        <Route path="/payroll" element={<PayrollHomEmployeePage />} />
        <Route path="/timesheets" element={employeeRoutes} />
        <Route path="/kitchen" element={<KitchenHomePage />} />
        <Route path="/" element={<OrganizationHomePage />} />
        <Route path="*" element={orgAdminRoutes} />
      </Routes>
    </div>
  );
};
