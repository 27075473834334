import { Button, Divider, Flex, Input, List, Modal } from "antd";
import { useFormik } from "formik";
import moment from "moment";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { useGetOrganizationSalesById } from "../../../api/api.get";
import { useUpdateSale } from "../../../api/api.update";
import { useNotifications } from "../../../components/notifications/notification";
import { PriceComponent } from "../../../components/price.component";
import { useCurrency } from "../../../hooks/useCurrency";
import { IOrganizationSale } from "../../../types/types";
import { capitalize } from "../../../utils/functions";
import ReceiptModal from "./receipt.modal";

export default function ViewSalesModal({
  open,
  onCancel,
  sales,
}: {
  sales?: IOrganizationSale;
  open: boolean;
  onCancel: () => void;
}) {
  const { id } = useParams();
  const { toAmountDisplay, currency } = useCurrency();
  const navigate = useNavigate();
  const [showReceipt, setShowReceipt] = useState<boolean>(false);

  const { notifySuccess, notifyError, notification } = useNotifications({
    title: "Success",
    subtitle: "Sale created successfully.",
  });
  const { data: saleData } = useGetOrganizationSalesById(id, sales?.id);

  const { mutateAsync, isPending } = useUpdateSale(id, sales?.id);

  const formik = useFormik({
    initialValues: {
      notes: sales?.notes ?? "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      notes: Yup.string(),
    }),
    onSubmit: async (values) => {
      mutateAsync(values)
        .then(() => {
          formik.resetForm();
          notifySuccess();
          onCancel();
        })
        .catch(() => notifyError());
    },
  });

  return (
    <Modal
      title="Order"
      open={open}
      onOk={formik.submitForm}
      onCancel={onCancel}
      okText="Save"
      okButtonProps={{ loading: isPending }}
    >
      <ReceiptModal
        open={showReceipt}
        onCancel={() => setShowReceipt(false)}
        sales={sales}
      />
      <form onSubmit={formik.handleSubmit}>
        {notification}
        {saleData?.products && saleData.products.length > 0 && (
          <div className="divSpread">
            <Button onClick={() => setShowReceipt(!showReceipt)} type="link">
              Receipt
            </Button>

            {saleData.type === "RETAIL" && (
              <Button
                onClick={() =>
                  navigate(`/organizations/${id}/sales/${sales?.id}/returns`)
                }
                type="link"
              >
                Return Items
              </Button>
            )}
          </div>
        )}

        <Flex>
          <Flex gap={20} vertical style={{ padding: 20, flex: 1 }}>
            {saleData?.category && (
              <div>
                <h4>Category</h4>
                <span>{saleData?.category}</span>
              </div>
            )}

            <div className="divColumn">
              <h4>Sale Date</h4>
              <div className="divFlex divAlignItemsOnly">
                <span className="mr-10">
                  {moment(saleData?.saleDate).format("DD MMM YYYY")}
                </span>
              </div>
            </div>

            <div>
              <h4>Net Total</h4>
              <span>{toAmountDisplay(saleData?.amount)}</span>
            </div>

            {saleData?.type === "RETAIL" && (
              <div className="divColumn">
                <h4>Discount Total</h4>
                <span>{toAmountDisplay(saleData?.discountTotal)}</span>
              </div>
            )}

            {!!saleData?.pointsTotal && (
              <div className="divColumn">
                <h4>Points</h4>
                <span>{toAmountDisplay(saleData?.pointsTotal)}</span>
              </div>
            )}

            <div className="divColumn">
              <h4>Sales Tax</h4>
              <span>{saleData?.tax}%</span>
            </div>

            <div className="divColumn">
              <h4>Total</h4>
              <span>{toAmountDisplay(saleData?.total)}</span>
            </div>

            <Divider />
            <h4> Payment Details</h4>

            <div>
              <span>
                Payment: {capitalize(saleData?.payments?.[0]?.paymentType)}
              </span>
            </div>

            <div>
              <span>
                Customer Phone:{" "}
                {saleData?.payments?.[0]?.customerPhone
                  ? saleData?.payments?.[0]?.customerPhone
                  : "-"}
              </span>
            </div>

            <Divider />

            <div>
              <h4>Merchant Notes</h4>
              <Input.TextArea
                placeholder="Notes"
                {...formik.getFieldProps("notes")}
              />
            </div>
          </Flex>
          {saleData?.products && saleData.products?.length > 0 && (
            <Flex gap={20} vertical style={{ padding: 20, flex: 1 }}>
              <div className="flex-1">
                <h4>Items</h4>
                <List
                  itemLayout="horizontal"
                  dataSource={saleData?.products}
                  bordered
                  renderItem={(item, index) => (
                    <List.Item key={index}>
                      <List.Item.Meta
                        title={capitalize(item.title)}
                        description={
                          <PriceComponent
                            price={item.price}
                            prefix={currency}
                          />
                        }
                      />
                    </List.Item>
                  )}
                />
              </div>

              <div className="divRight">
                <h1>{toAmountDisplay(saleData?.total)}</h1>
              </div>
            </Flex>
          )}
        </Flex>
      </form>
    </Modal>
  );
}
