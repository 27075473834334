import { InfoCircleFilled } from "@ant-design/icons";
import { Tooltip } from "antd";

export function InfoIcon({ title }: { title?: string }) {
  return (
    <Tooltip title={title}>
      <InfoCircleFilled style={{ color: "var(--exhut-blue)" }} />
    </Tooltip>
  );
}

export function WarnIcon({ title }: { title?: string }) {
  return (
    <Tooltip title={title}>
      <img
        src="/assets/warning.png"
        alt="warning"
        style={{ width: 25, height: 25 }}
      />
    </Tooltip>
  );
}

export function CheckIcon({ title }: { title?: string }) {
  return (
    <Tooltip title={title}>
      <img
        src="/assets/checked.png"
        alt="success"
        style={{ width: 25, height: 25 }}
      />
    </Tooltip>
  );
}
