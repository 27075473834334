import { Button, Popconfirm, Table, Typography } from "antd";
import moment from "moment";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDeleteCRM } from "../../../api/api.delete";
import { useGetCRM } from "../../../api/api.get";
import AddCrmModal from "./add-crm-modal";

const { Title } = Typography;

export default function OrganizationCRMHomePage() {
  const { id } = useParams();

  const { data: expenses, refetch } = useGetCRM(id);

  const navigate = useNavigate();

  const { mutateAsync: deleteAsync } = useDeleteCRM(id!!);
  const [isAdd, setIsAdd] = useState<boolean>(false);

  const handleDelete = (row: any) => {
    deleteAsync({ crmId: row.id })
      .then(() => refetch())
      .catch(console.error);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Type",
      key: "type",
      render: (value: any) => (
        <span>
          {value.allCustomers === true ? "All Customers" : "Specific"}
        </span>
      ),
    },
    {
      title: "Created",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (value: string) => (
        <span>{moment.utc(value).local().format("DD MMM YYYY")}</span>
      ),
    },
    {
      title: "",
      key: "operationView",
      width: 100,
      render: (row: any) => (
        <Button
          type="link"
          onClick={() => navigate(`/organizations/${id}/crm/${row.id}`)}
        >
          Edit
        </Button>
      ),
    },
    {
      title: "",
      key: "operation",
      width: 100,
      render: (row: any) => (
        <Popconfirm
          title="Delete"
          description="Are you sure to delete?"
          onConfirm={() => handleDelete(row)}
          okText="Yes"
          cancelText="No"
        >
          <Button danger type="text">
            Delete
          </Button>
        </Popconfirm>
      ),
    },
  ];

  return (
    <div
      style={{ marginBottom: 100 }}
      className="card divColumn mx-40 my-40 flex-1"
    >
      <AddCrmModal open={isAdd} onCancel={() => setIsAdd(false)} />

      <div className="divSpread divAlignItemsOnly">
        <div className="divColumn ">
          <Title level={3}>CRM</Title>
          <Title level={5}>
            Send discounts and offers to your customers via SMS
          </Title>
        </div>
        <Button type="link" onClick={() => setIsAdd(!isAdd)}>
          Add
        </Button>
      </div>

      <Table dataSource={expenses} columns={columns} />
    </div>
  );
}
