import {
  Alert,
  AutoComplete,
  AutoCompleteProps,
  Checkbox,
  Flex,
  Input,
  Modal,
  Spin,
} from "antd";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDebounce } from "use-debounce";
import * as Yup from "yup";
import { useCreateVendor } from "../../../api/api";
import { useSearchVendors } from "../../../api/api.get";
import ErrorPanel from "../../../components/error.panel.component";
import { useNotifications } from "../../../components/notifications/notification";
import { useCurrency } from "../../../hooks/useCurrency";
import { IVendorSearchResult } from "../../../types/types";

export default function AddVendorModal({
  open,
  onCancel,
  onOk,
}: {
  open: boolean;
  onOk: () => void;
  onCancel: () => void;
}) {
  const { id } = useParams();
  const [error, setError] = useState<string>();
  const { notification } = useNotifications();
  const [servicesSearchText, setServicesSearchText] = useState<string>("");
  const [debouncedProductNameSearchText] = useDebounce(servicesSearchText, 300);
  const [options, setOptions] = useState<AutoCompleteProps["options"]>([]);
  const { areaCode } = useCurrency();

  const { data: vendorData, isLoading } = useSearchVendors(
    id,
    debouncedProductNameSearchText,
  );

  useEffect(() => {
    if (vendorData) {
      setOptions(
        vendorData.map((v: IVendorSearchResult) => {
          return {
            value: v.name,
            label: v.name,
            id: v.id,
          };
        }),
      );
    }
  }, [vendorData, debouncedProductNameSearchText]);

  const { mutateAsync, isPending } = useCreateVendor(id);

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      isExternalVendor: false,
      vendorId: undefined,
    },
    validationSchema: Yup.object({
      vendorId: Yup.string().when("isExternalVendor", {
        is: false,
        then: (schema) => schema.required("Please select a vendor"),
      }),
      name: Yup.string().when("isExternalVendor", {
        is: true,
        then: (schema) => schema.required("Please enter name"),
      }),
      isExternalVendor: Yup.bool(),
      email: Yup.string().when("isExternalVendor", {
        is: true,
        then: (schema) => schema.required("Please enter email"),
      }),
      phone: Yup.string().when("isExternalVendor", {
        is: true,
        then: (schema) =>
          schema
            .matches(/^(0|[1-9]\d*)(\.\d+)?$/, "Please enter valid price")
            .required("Please enter phone"),
      }),
    }),
    onSubmit: async (values) => {
      setError(undefined);
      // @ts-ignore
      delete values["isExternalVendor"];
      mutateAsync({ ...values, phone: `${areaCode}${values.phone}` })
        .then(() => {
          formik.resetForm();
          onOk();
        })
        .catch((err) => {
          console.error("Error adding vendor" + err.message);
          if (err.response?.data?.statusCode === 409) {
            setError(
              err.response?.data?.message ?? "Whoops! Something went wrong",
            );
          } else {
            setError("Whoops! Something went wrong.");
          }
        });
    },
  });

  console.log(formik.errors);
  return (
    <form onSubmit={formik.handleSubmit}>
      {notification}
      <Modal
        title="New Vendor"
        open={open}
        loading={isPending}
        onOk={formik.submitForm}
        onCancel={() => {
          formik.resetForm();
          onCancel();
        }}
      >
        <Flex gap={30} vertical style={{ padding: 20 }}>
          <AutoComplete
            id={`search-vendor`}
            allowClear
            options={options}
            onSelect={(p: any, option: any) => {
              formik.setFieldValue("vendorId", option.id);
            }}
            onSearch={(text) => setServicesSearchText(text)}
            notFoundContent={
              <div>
                {isLoading && <Spin size="small" />}
                {!isLoading && <div>No vendors found by that name.</div>}
              </div>
            }
            style={{ borderRadius: 10 }}
            disabled={formik.values.isExternalVendor}
          >
            <Input
              style={{ fontSize: 15 }}
              size="middle"
              placeholder="Search vendor by name..."
            />
          </AutoComplete>

          <div>
            <Checkbox
              onChange={(e) =>
                formik.setFieldValue("isExternalVendor", e.target.checked)
              }
              checked={formik.values.isExternalVendor}
            >
              <span>My Vendor Is Not In ExpenseHut</span>
            </Checkbox>
          </div>

          {formik.values.isExternalVendor && (
            <Flex className="my-20" gap={30} vertical>
              <div>
                <Input
                  maxLength={50}
                  placeholder="Name"
                  {...formik.getFieldProps("name")}
                />
                <ErrorPanel message={formik.errors.name} />
              </div>

              <div>
                <Input
                  maxLength={50}
                  placeholder="Email"
                  {...formik.getFieldProps("email")}
                />
                <ErrorPanel message={formik.errors.email} />
              </div>

              <div>
                <Input
                  maxLength={50}
                  prefix={areaCode}
                  placeholder="Phone"
                  {...formik.getFieldProps("phone")}
                />
                <ErrorPanel message={formik.errors.phone} />
              </div>
            </Flex>
          )}

          <div>{error && <Alert type="error" message={error} />}</div>
        </Flex>
      </Modal>
    </form>
  );
}
