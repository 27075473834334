import { STOCKREQUEST_STATUS } from "../types/types";

export function getStockLifecycleOptions(
  isMerchant: boolean,
  isVendor: boolean,
  currentStatus?: string,
) {
  if (!currentStatus) {
    return [];
  }
  if (isMerchant) {
    if (currentStatus === STOCKREQUEST_STATUS.SAVED) {
      return [
        {
          label: "Send",
          value: STOCKREQUEST_STATUS.SUBMITTED,
        },
      ];
    } else if (currentStatus === STOCKREQUEST_STATUS.SUBMITTED) {
      return [
        {
          label: "Cancel",
          value: STOCKREQUEST_STATUS.CANCELLED,
        },
      ];
    } else if (currentStatus === STOCKREQUEST_STATUS.COMPLETED) {
      return []; // no actions
    } else if (currentStatus === STOCKREQUEST_STATUS.ON_ROUTE) {
      return [
        {
          label: "Complete",
          value: STOCKREQUEST_STATUS.COMPLETED,
        },
        {
          label: "Delayed",
          value: STOCKREQUEST_STATUS.DELAYED,
        },
      ];
    } else {
      return [
        {
          label: "Complete",
          value: STOCKREQUEST_STATUS.COMPLETED,
        },
      ];
    }
  }
  if (isVendor) {
    if (currentStatus === STOCKREQUEST_STATUS.SAVED) {
      return [];
    } else if (currentStatus === STOCKREQUEST_STATUS.SUBMITTED) {
      return [
        {
          label: "Processing",
          value: STOCKREQUEST_STATUS.PROCESSING,
        },
      ];
    } else if (currentStatus === STOCKREQUEST_STATUS.COMPLETED) {
      return []; // no actions
    } else {
      return [
        {
          label: "Processing",
          value: STOCKREQUEST_STATUS.PROCESSING,
        },
        {
          label: "Blocked",
          value: STOCKREQUEST_STATUS.BLOCKED,
        },
        {
          label: "Delayed",
          value: STOCKREQUEST_STATUS.DELAYED,
        },
        {
          label: "On Route",
          value: STOCKREQUEST_STATUS.ON_ROUTE,
        },
      ];
    }
  }

  return [];
}
